import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CommonService } from '../../common-services/common-functions.service';
import { AccessLevel, ShortCutKeys } from '../../common-services/enum.service';
import { IShortCutKeys } from '../../common-services/interface.service';
import { ShortcutService } from '../../common-services/shortcut.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.css'],
})
export class FormButtonsComponent implements OnInit, OnDestroy {
  pageProperties = new FormButtonProperties(AccessLevel.None)
  accessLevel: AccessLevel;
  watcher: Subscription;
  @Input() enableEdit: false;

  @Input() set fromProperties(v: any) {
    if (!isNullOrUndefined(v)) {
      this.pageProperties = v;
    }
  }

  @Output() buttonAction = new EventEmitter<{ action: AccessLevel, }>();

  checkAccess(no: number) {
    return (this.pageProperties.accessRights & no) > 0;
  }

  constructor(public sharedService: SharedService, private shortcut: ShortcutService) { }

  ngOnInit() {
    this.sharedService.formBtn.actionAccess = 0;
    this.watcher = this.sharedService.shortCutKeyListner.subscribe((t: IShortCutKeys) => {
      this.shortcutKeyEvent(t);
    });
  }

  shortcutKeyEvent(t: IShortCutKeys) {
    switch (t.key) {
      case ShortCutKeys.Alt1:
        if (this.sharedService.formBtn.actionAccess === 0) { this.onActionClick(3, AccessLevel.New); };
        break;
      case ShortCutKeys.Alt2:
        if (this.sharedService.formBtn.actionAccess === 0) { this.onActionClick(5, AccessLevel.Edit); }
        break;
      case ShortCutKeys.Alt3:
        if (this.sharedService.formBtn.actionAccess === 0) { this.buttonAction.emit({ action: AccessLevel.Export }); }
        break;
      case ShortCutKeys.Alt4:
        if (this.sharedService.formBtn.actionAccess === 0) { this.onActionClick(AccessLevel.Back, AccessLevel.Back); }
        break;
      case ShortCutKeys.AltS:
        this.onActionClick(AccessLevel.Save, AccessLevel.Save);
        break;
      case ShortCutKeys.AltF:
        CommonService.focusControlById('txtTableFilter');
        break;
      default:
        break;
    }
  }

  onActionClick(btnAccess, actionBtn) {
    if (btnAccess !== -1 && btnAccess !== - 2) {
      this.sharedService.formBtn.actionAccess = btnAccess;
    }
    this.buttonAction.emit({ action: actionBtn });
  }

  ngOnDestroy(): void {
    if (!isNullOrUndefined(this.watcher)) {
      this.watcher.unsubscribe();
    }
  }
}

export class FormButtonProperties {
  constructor(private _accessRights: number) {
    this.accessRights = _accessRights;
  }
  accessRights: AccessLevel;
}

// shortcutKeyConfig() {
    // this.keyboard.add([
    //   {
    //     key: 'alt s',
    //     allowIn: ["BUTTON"],
    //     command: () => { this.onActionClick(AccessLevel.Save, AccessLevel.Save); },
    //   },
    //   {
    //     key: 'alt f',
    //     allowIn: ["INPUT"],
    //     command: () => { CommonService.focusControlById('txtTableFilter'); }
    //   },
    //   {
    //     key: 'alt 1',
    //     command: () => {
    //       if (this.sharedService.formBtn.actionAccess === 0) { this.onActionClick(3, AccessLevel.New); }
    //     }
    //   },
    //   {
    //     key: 'alt 2',
    //     command: () => {
    //       if (this.sharedService.formBtn.actionAccess === 0) { this.onActionClick(5, AccessLevel.Edit); }
    //     }
    //   },
    //   {
    //     key: 'alt 3',
    //     command: () => {
    //       if (this.sharedService.formBtn.actionAccess === 0) { this.buttonAction.emit({ action: AccessLevel.Export }); }
    //     }
    //   },
    //   {
    //     key: 'alt 4',
    //     command: () => {
    //       if (this.sharedService.formBtn.actionAccess === 0) { this.onActionClick(AccessLevel.Back, AccessLevel.Back); }
    //     }
    //   },
    //   {
    //     key: 'ctrl shift f',
    //     command: () => console.log('ctrl + shift + f'),
    //   },
    //   {
    //     key: 'cmd f',
    //     command: () => console.log('cmd + f'),
    //     preventDefault: true
    //   }
    // ]);
 // }