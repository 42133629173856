import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { AppInitialize } from '../app-init.service';
import { CommonService, Logger } from '../common-services/common-functions.service';
import { CommunicationService } from '../common-services/communication.service';
import { TimeoutComponent } from './timeout/timeout.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  pageAccessLevel = 127; // TODO: REMOVE STATIC PARAMETER
  isTimerStart = false;
  dialogRef: MatDialogRef<TimeoutComponent>;
  constructor(private http: HttpClient,
    public dialog: MatDialog,
    private appInit: AppInitialize,
    private router: Router,
    private webSocket: CommunicationService,
    public userIdle: UserIdleService) {
  }

  private isUserLoggedIn = this.hasToken();
  userRightsList: any[];

  public get AppConfig(): AppInitialize {
    return this.appInit;
  }

  public get isAuthenticated(): boolean {
    return (this.isUserLoggedIn && this.hasToken());
  }

  public login(user: string, password: string): Observable<any> {

    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' }),
    // };

    // const reqParam = 'grant_type=password&username=' + user
    //   + '&password=' + encodeURI(password) + '&client_id=WebApp';

    // return this.http.post<any>('token', reqParam, httpOptions)
    //   .map(data => {
        
    //     this.isUserLoggedIn = true;
    //     localStorage.setItem('AuthorizeData', JSON.stringify(data));
    //     this.initIdleService();
    //     return data;
    //   });

    return this.http.post<any>('account/Userlogin', {  UserName: user, Password: password })
    .map(data => {
      this.isUserLoggedIn = true;
      localStorage.setItem('AuthorizeData', JSON.stringify(data));
      this.initIdleService();
      return data;
    });

  }

  private hasToken() {
    return !!localStorage.getItem('AuthorizeData');
  }

  logOut(anyParam = ''): any {
    try {
      this.pageAccessLevel = 0;
      this.userRightsList = [];
      localStorage.removeItem('AuthorizeData');
      if (anyParam === '') {
        this.router.navigate(['auth/login']);
      } else {
        this.router.navigate(['auth/login'], { fragment: anyParam });
      }
      this.userIdle.stopWatching();
      this.disconnectWebSocket();
    }
    catch (error) {
      console.log(error);
    }
    finally {
    }
  }

  disconnectWebSocket() {
    try {
      this.webSocket.disconnectSocket();
    }
    catch (error) {
      console.log(error);
    }
    finally {
    }
  }

  getUserRightsTree(userId, role, isTree = true): any {
    return this.http.get<any[]>(`rights/getrightstree?userId=${userId}&role=${role}&isTree=${isTree}`).pipe(share());
  }

  validateUserRight(moduleId, url = ''): Observable<boolean> {
    Logger.Info(moduleId);
    if ((moduleId) || url != '') {
      if ((this.userRightsList) && this.userRightsList.length > 0) {
        return Observable.of(this.validateModule(moduleId, url));
      } else {
        return this.getUserRightsTree(0, '', false).map((t) => {
          this.userRightsList = t.data;
          return this.validateModule(moduleId, url);
        });
      }
    } else {
      Observable.of(false);
    }
  }

  validateModule(moduleId, url = '') {
    let selectedRights: any;
    if ((moduleId)) {
      selectedRights = this.userRightsList.find((t) => t.id === moduleId);
      if ((selectedRights)) {
        this.pageAccessLevel = selectedRights.userAccessLevel;
        // return !isNullOrUndefined(this.userRightsList.find((t) => t.id === moduleId && (t.userAccessLevel & 1) > 0));
        return (selectedRights.userAccessLevel & 1) > 0;
      } else {
        return false;
      }
    } else if (url != '') {
      selectedRights = this.userRightsList.find((t) => t.link === ((url != '/') ? '.' + url : url));
      if ((selectedRights)) {
        this.pageAccessLevel = selectedRights.userAccessLevel;
        /// return !isNullOrUndefined(this.userRightsList.find((t) => t.link === url && (t.userAccessLevel & 1) > 0));
        if ((selectedRights.userAccessLevel & 1) > 0) {
          CommonService.AddLink(selectedRights.link, selectedRights.title);
          return true
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      Logger.Info(moduleId);
      Logger.Info(url);
      return false;
    }
  }

  /**
   * IDLE SERVICE
   * SERVICE CHECK FOR USER ACTIVE OR NOT ON USER IDLE FOR 10 MIN,
   * THEN ITS ASK FINALLY VIA POPUP MESSAGE WITH LOGOFF AND CONTINUE BUTTON
   * ON CLICKING LOGOFF BUTTON LOGOUT FROM SYSTEM AND WITH CONTINUE BUTTON CONTINUE WITH CURRENT SESSION
   * CREATED BY PARIMAL: 22/03/2019
   */
  initIdleService() {
    Logger.Normal('Start From Auth Service');
    if ((this.appInit.idleTime) && this.appInit.idleTime > 0) {
      //Start watching for user inactivity.
      this.userIdle.setConfigValues({ idle: this.appInit.idleTime, timeout: this.appInit.timeoutTime, ping: this.appInit.pingTime });
      this.userIdle.startWatching();

      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe(count => {
        if (!this.isTimerStart && this.isAuthenticated) {
          this.isTimerStart = true;
          this.openDialog(); Logger.Normal(count);
          this.dialogRef.componentInstance.totalCount = this.appInit.timeoutTime;
        }
        if ((this.dialogRef) && (this.dialogRef.componentInstance)) {
          this.dialogRef.componentInstance.timeCount = count;
        }
      });

      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        this.dialogRef.close(); console.log('Time is up!'); this.isTimerStart = false; this.logOut();
      });
    }
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(TimeoutComponent, {
      width: '400px',
      data: new TimeOut(this.getFullName),
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      Logger.Normal(result);
      if (result === 'continue') {
        this.userIdle.resetTimer();
      } else if (result === 'logoff') {
        this.logOut('sessionexpired');
      }
      this.isTimerStart = false;
    });
  }

  public get getUserName(): string {
    return this.getStorageDetail('userName');
  }

  public get getFullName(): string {
    return this.getStorageDetail('fullName');
  }

  public get CanApprovePO(): boolean {
    return (this.getStorageDetail('canapprovepo') === 'True');
  }

  public get CanApproveDistPO(): boolean {
    return (this.getStorageDetail('canapprovedistpo') === 'True');
  }

  public get UserRole(): string {
    return this.getStorageDetail('role');
  }

  public get getToken(): any {
    return this.getStorageDetail('token');
  }

  public get hasFullRight(): boolean {
    return (this.getStorageDetail('hfr', 'false') === 'True');
  }

  public get getUserMenu(): any[] {
    return JSON.parse(this.getStorageDetail('userMenu'));
  }

  public get CanDisapproveStoreExecution(): boolean {
    return (this.getStorageDetail('candisapprovestoreexecution') === 'True');
  }

  getStorageDetail(keyName, returnDefault = null): string {
    const localObj = this.hasToken() ? JSON.parse(localStorage.getItem('AuthorizeData')) : '';
    if (localObj === '') {
      return !(returnDefault) ? '' : returnDefault;
    }
    if (!localObj[keyName] && keyName === 'userMenu') {
      this.logOut();
    }
    switch (keyName) {
      case 'token':
        return `Bearer ${localObj.access_token}`;
      default:
        return localObj[keyName];
    }
  }

}

export class TimeOut {
  constructor(private _userName: string) {
    this.userName = _userName;
  }
  userName: string;
}