import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { CommonService, Logger } from '../../../common-services/common-functions.service';
import { ExcelService } from '../../../common-services/excel.service';
import { cellDef, TableObject } from '../../../common-services/models.service';
import { SnackBarService } from '../../../common-services/snack-bar.service';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-sub-report',
  templateUrl: './sub-report.component.html',
  styleUrls: ['./sub-report.component.css']
})
export class SubReportComponent implements OnInit {

  reportObj: any;
  dataSource = new MatTableDataSource<any>([]);
  tableObject: TableObject;
  isSearching: boolean;
  sectionHeight: number;
  filterDisplay = '';
  headerData = {};
  headerName = '';
  constructor(public dialogRef: MatDialogRef<SubReportComponent>,
    private snackbar: SnackBarService,
    private sharedService: SharedService,
    private excelService: ExcelService,

    @Inject(MAT_DIALOG_DATA) private confModel: any) {
    if (confModel) {
      this.reportObj = confModel
      if (this.reportObj && this.reportObj.reportInfo) {
        const cellArray: any = this.getTableCellArray(this.reportObj.tablerConfig);
        this.tableObject = new TableObject(cellArray.tableCellArray, this.reportObj.reportInfo.viewButtonsSum, this.reportObj.reportInfo.isPagingEnable, cellArray.defaultSearch, this.sharedService.getSearchFilter(this.reportObj.selectedCell.subReportId), this.reportObj.selectedCell.subReportId);
        this.onFilterEvent({ filter: this.sharedService.getSearchFilter(this.reportObj.selectedCell.subReportId) }, true);
        this.sectionHeight = CommonService.mainSectionLW.height - 432;
      } else {
        this.snackbar.warning("Report Configuration Not Found");
      }
    }
  }

  ngOnInit() { }

  getTableCellArray(data: any): any {
    const _tableCellArray = [];
    let _defaultSearch = this.reportObj.reportInfo.defaultSearch;
    if (data) {
      data.forEach(element => {
        // if (element.isDisplay) {
          _tableCellArray.push(new cellDef(element.tableFieldName, element.headerName, element.showFieldDDL, element.fieldType, element.footerFields, null, element.cellCss, element.fieldPrefix, element.subReportId, element.isDisplay));
        // }
        if (element.isDefaultSearch) {
          _defaultSearch = element.tableFieldName;
        }
      });
    }
    return { tableCellArray: _tableCellArray, defaultSearch: _defaultSearch };
  }
  onTableButtonAction(event) { }
  exportReport(dataArr) {
    if (this.reportObj.reportInfo.reportInfo.isPagingEnable) {
      let filter = CommonService.copyObject(this.sharedService.getSearchFilter(this.reportObj.selectedCell.subReportId));
      filter.pageSize = 100000;
      this.sharedService.customPostApi('common/reportlist?type=' + this.reportObj.selectedCell.subReportId, filter).subscribe((x: any) => {
        if (x.status === true) {
          if (!isNullOrUndefined(x.data) && x.data.length > 0) {
            this.excelService.exportDatatoExcel(x.data, dataArr, this.reportObj.reportInfo.exportReportName);
          } else {
            this.snackbar.info('Record Not Found');
          }
        } else {
          this.snackbar.warning(x.message === '' ? 'Something went wrong' : x.message);
        }
      });
    } else {
      if (this.dataSource.data && this.dataSource.data.length > 0) {
        if (this.dataSource.filteredData && this.dataSource.filteredData.length > 0) {
          this.excelService.exportDatatoExcel(this.dataSource.filteredData, dataArr, this.reportObj.reportInfo.exportReportName);
        } else {
          this.excelService.exportDatatoExcel(this.dataSource.data, dataArr, this.reportObj.reportInfo.exportReportName);
        }
      }
    }
  }

  onFilterEvent(event, allow = false) {
    // const cell: cellDef = this.reportObj.selectedCell;
    // const wherePreCondition = (this.reportObj.selectedRecord && this.reportObj.selectedRecord.example) ? this.reportObj.selectedRecord.example : '';
    // if (!event.filter.filterArray.find((t) => t.key == cell.displayName) && wherePreCondition == '') {
    //   event.filter.filterArray.push({ key: cell.displayName, value: this.reportObj.selectedRecord[cell.displayName] })
    // } else {
    //   event.filter.example = wherePreCondition;
    // }
    const cell: cellDef = this.reportObj.selectedCell;
    if (!event.filter.filterArray.find((t) => t.key == cell.displayName)) {
      event.filter.filterArray.push({ key: cell.displayName, value: this.reportObj.selectedRecord[cell.displayName] })
    } else {
      event.filter.filterArray.filter((t) => {
        if (t.key == cell.displayName) { t.value = this.reportObj.selectedRecord[cell.displayName]; }
      });
    }
    
    this.filterDisplay = `${(this.reportObj.reportInfo.reportName ? this.reportObj.reportInfo.reportName + ' of ' : '')} ${cell.headerName} : ${this.reportObj.selectedRecord[cell.displayName]} `;
    if (this.reportObj.reportInfo.isPagingEnable || allow) {
      this.isSearching = true;
      this.sharedService.customPostApi('common/reportlist?type=' + this.reportObj.selectedCell.subReportId, event.filter).subscribe((t: any) => {
        this.dataSource = new MatTableDataSource<any>(t.data);
        this.isSearching = false;
      }, (error) => {
        Logger.Error(error);
        this.isSearching = false;
      });
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

}
