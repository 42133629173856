import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';
import { isNullOrUndefined } from 'util';
import { CommonService, Logger } from './common-functions.service';

const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();

export class ValidatorsService {

  static PhoneNumberValidator = (regionCode: string = 'IN'): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      let validNumber = false;
      try {
        if (!isNullOrUndefined(control.value) && control.value.trim() !== '') {
          const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
          validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
        } else {
          return null;
        }
      } catch (e) {
        Logger.Warn(e);
      }
      return validNumber ? null : { 'wrongNumber': { value: control.value } };
    }
  };
}

export class FormServices {
  static focusFormValidationErrors(fg: FormGroup) {
    let keyName = '';
    Object.keys(fg.controls).forEach(key => {
      const controlErrors: ValidationErrors = fg.get(key).errors;
      if (controlErrors != null && keyName === '') {
        keyName = key;
        CommonService.focusControlById(keyName);
        Object.keys(controlErrors).forEach(keyError => {
          Logger.Warn('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {

  public static strong(control: FormControl): ValidationResult {
    // const hasNumber = /\d/.test(control.value);
    // const hasUpper = /[A-Z]/.test(control.value);
    // const hasLower = /[a-z]/.test(control.value);
    const valid = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@$!%*?&])[A-Za-z\d$@$!%*?&#].{8,}/.test(control.value);
    // new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}').test(control.value);
    // const pwdLength = control.value.length;
    // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
    // const valid = hasNumber && hasUpper && hasLower && pwdLength >= 6;
    if (!valid) {
      // return what´s not valid
      return { strong: true };
    }
    return null;
  }
}


export class CustomeDateValidators {
  static fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
      return (formGroup: FormControl): { [key: string]: boolean } | null => {
          const fromDate = formGroup.get(fromDateField).value;
          const toDate = formGroup.get(toDateField).value;
         // Ausing the fromDate and toDate are numbers. In not convert them first after null check
          if ((fromDate !== null && toDate !== null) && fromDate > toDate) {
              return {[errorName]: true};
          }
          return null;
      };
  }
}