import { Component, Inject, OnInit } from '@angular/core';
import { DateAdapter, MatDialog, MatDialogRef, MAT_DATE_FORMATS, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService, DateService } from '../../common-services/common-functions.service';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../auth/auth.service';
import { SnackBarService } from '../../common-services/snack-bar.service';
import { SharedService } from '../../shared/shared.service';
import { forkJoin } from 'rxjs';
import { ImageListPreviewComponent } from '../image-list-preview/image-list-preview.component';
import { Documents } from '../../common-services/models.service';
import { IndianDateadapterService, MY_DATE_FORMATS } from '../../common-services/indian-dateadapter.service';

@Component({
  selector: 'app-expense-book',
  templateUrl: './expense-book.component.html',
  styleUrls: ['./expense-book.component.css'],
  providers: [
    { provide: DateAdapter, useClass: IndianDateadapterService },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
})
export class ExpenseBookComponent implements OnInit {
  allowToChange = false;
  docList = [];
  SelectedCashbook: any;
  ExpenseList: any;
  ExpenseDetailsList: any;
  ExpenseDetailsImageList: any;
  IsLoaderShow: boolean = false
  fileList = [];
  dataSource = [];
  strRemarks: string;
  private dialogImageRef: MatDialogRef<ImageListPreviewComponent>;
  constructor(private dialog: MatDialog, private authService: AuthService, public dialogRef: MatDialogRef<ExpenseBookComponent>,
    private snackbar: SnackBarService, private sharedService: SharedService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.IsLoaderShow = true;
    this.SelectedCashbook = data.header;

    this.allowToChange = (isNullOrUndefined(this.SelectedCashbook.status) || this.SelectedCashbook.status === '');
    this.sharedService.customGetApi('cashbook/getcashbookdetail?Id=' + this.SelectedCashbook.cposId).subscribe((x: any) => {
      if (x.status == true) {
        
        this.ExpenseList = x.data.header;
        this.strRemarks = x.data.header.cposremarks
        this.ExpenseDetailsList = x.data.details;
        this.ExpenseDetailsImageList = x.data.attachmentDetails;
        // this.imagecount = this.InvoiceBatchList.filter(a => a.isChecked != true)
        this.IsLoaderShow = false;
        // this.Remark = x.data.pOdata.remarks
      } else {
        this.IsLoaderShow = false;
        this.snackbar.warning(x.message === '' ? 'Something went wrong' : x.message);
      }
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.dialogImageRef) {
      this.dialogImageRef.close();
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  onApproveOrReject(type: any) {
    if (!isNullOrUndefined(this.strRemarks)) {
      this.sharedService.customPostApi('cashbook/approverejectcashbook', {
        Status: type, Id: this.SelectedCashbook.cposId, Remark: this.strRemarks
      }).subscribe((t: any) => {
        this.SelectedCashbook.cposremarks = this.strRemarks;
        this.SelectedCashbook.status = type;
        if (t.status == true) {
          this.snackbar.success(t.message)
          this.dialogRef.close(this.SelectedCashbook);
        } else {
          this.snackbar.error(t.message);
        }

      });
    }
    else {
      this.snackbar.error('Please Enter Remarks');
    }
  }

  onSave() {
    // if (!isNullOrUndefined(this.invoiceNo)) {
    //   if (!isNullOrUndefined(this.invoiceDate)) {
    //     if (!isNullOrUndefined(this.Remark)) {

    //       if (this.fileList.length > 0) {
    //         if (this.InvoiceBatchList.filter(a => a.isChecked == true && (a.remarks == null || a.remarks == undefined)) != 0) {
    //           this.dataSource = [];
    //           var cnt = this.InvoiceBatchList.filter(a => a.isChecked == true && (a.remarks == null || a.remarks == undefined))

    //           for (let i = 0; i < cnt.length; i++) {
    //             var index = this.InvoiceBatchList.findIndex(a => a.id == cnt[i].id);
    //             this.fileList[0].id = this.InvoiceBatchList[index].id
    //             this.fileList[0].batchNo = this.InvoiceBatchList[index].batchNo
    //             this.fileList[0].document = "Batch Invoice";
    //             let flst = Object.assign({}, this.fileList[0]);

    //             this.dataSource.push(flst);
    //           }

    //           this.sharedService.customPostApi('uploadinvoice/uploadinvoiceno', {
    //             DispatchNo: this.SelectedInvoice.dispatchNo, InvoiceNo: this.invoiceNo, InvoiceDate: DateService.getUTCDate(this.invoiceDate), BatchNo: this.InvoiceBatchList.filter(a => a.isChecked == true && (a.remarks == null || a.remarks == undefined)).map(a => a.batchNo).join(','), Remarks: this.Remark
    //           }).subscribe((t: any) => {

    //             if (t.status == true) {
    //               if (this.dataSource.length > 0) {
    //                 const files = this.dataSource;
    //                 const postReq = [];
    //                 files.forEach((element) => {
    //                   postReq.push(
    //                     this.sharedService.uploadFileMultiple(element.file,
    //                       {
    //                         id: element.id,
    //                         docType: element.document,
    //                         batchNo: element.batchNo,
    //                         docTypeId: element.documentType,
    //                         docRemarks: this.Remark,
    //                       })
    //                   );
    //                   // postReq.push(this.sharedService.uploadFile(element.file, { id: element.id, docType: element.documentType }));

    //                 });
    //                 forkJoin(postReq).subscribe((x) => {
    //                   this.fileList = [];
    //                   this.dataSource = [];
    //                   this.SuccessClose(t.message);

    //                 }, (error) => {
    //                   console.log('Something went wrong');
    //                   this.SuccessClose('File upload failed. Kindly upload again', false);

    //                 });

    //               }
    //               else {
    //                 this.SuccessClose(t.message);

    //               }
    //             } else {
    //               this.snackbar.error(t.message);
    //             }

    //           });
    //         }
    //         else {
    //           this.snackbar.error('Please Select Atleast One Batch');
    //         }
    //       }
    //       else {
    //         this.snackbar.error('Please upload Image');
    //       }
    //     }
    //     else {
    //       this.snackbar.error('Please Enter Remarks');
    //     }
    //   }
    //   else {
    //     this.snackbar.error('Please Enter Invoice Date');
    //   }
    // }
    // else {
    //   this.snackbar.error('Please Enter Invoice No');
    // }
  }

  // private SuccessClose(message, isSuccess = true) {
  //   isSuccess ? this.snackbar.success(message) : this.snackbar.error(message);
  //   this.dialogRef.close(true);
  // }

  previewDoc(item) {
    
    if (item) {
      this.dialogImageRef = this.dialog.open(ImageListPreviewComponent, {
        width: String(CommonService.mainSectionLW.width - 100) + 'px',
        height: String(CommonService.mainSectionLW.height - 100) + 'px',
        disableClose: false,
        closeOnNavigation: false
      });
      this.dialogImageRef.componentInstance.isPreviewFirst = true;
      this.dialogImageRef.componentInstance.boxHeight = CommonService.mainSectionLW.height - 200;
      this.dialogImageRef.componentInstance.isPopup = true;
      this.dialogImageRef.componentInstance.apiUrl = 'common/getfile?filePath=';
      if (item.fileName == null && item.path == null) {
        this.dialogImageRef.componentInstance.docuementList = this.ExpenseDetailsImageList.filter(a => a.detailId == item.detailId);
      }
      else {
        this.dialogImageRef.componentInstance.docuementList = [new Documents(item.fileName, item.path)];
      }
    }


  }

  onCancelClick() {
    this.dialogRef.close();
  }
}
