import { Injectable, OnDestroy } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { ShortCutKeys } from './enum.service';

@Injectable({
  providedIn: 'root'
})
export class ShortcutService implements OnDestroy {

  constructor(private sharedService: SharedService) { this.shortcutKeyConfig(); }

  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   console.log(event);
  //   if (event.keyCode === KEY_CODE.RIGHT_ARROW) {

  //   }
  //   if (event.keyCode === KEY_CODE.LEFT_ARROW) {

  //   }
  // }

  shortcutKeyConfig() {
    // this.keyboard.add([
    //   {
    //     key: 'alt r',
    //     allowIn: ["BUTTON", "INPUT"],
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.AltR, description: 'Reset' }); },
    //   },
    //   {
    //     key: 'alt a',
    //     allowIn: ["BUTTON", "INPUT"],
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.AltA, description: 'Advance Search' }); },
    //   },
    //   {
    //     key: 'alt x',
    //     allowIn: ["BUTTON", "INPUT"],
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.AltX, description: 'Reset' }); },
    //   },
    //   {
    //     key: 'alt s',
    //     allowIn: ["BUTTON", "INPUT"],
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.AltS, description: 'Save' }); },
    //   },
    //   {
    //     key: 'alt f',
    //     allowIn: ["INPUT"],
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.AltS, description: '' }); }
    //   },
    //   {
    //     key: 'alt c',
    //     allowIn: ["BUTTON"],
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.AltC, description: '' }); },
    //   },
    //   {
    //     key: 'alt 1',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt1, description: '' }); }
    //   },
    //   {
    //     key: 'alt 2',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt2, description: '' }); }
    //   },
    //   {
    //     key: 'alt 3',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt3, description: '' }); }
    //   },
    //   {
    //     key: 'alt 4',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt4, description: '' }); }
    //   },
    //   {
    //     key: 'alt 5',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt5, description: '' }); }
    //   },
    //   {
    //     key: 'alt 6',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt6, description: '' }); }
    //   },
    //   {
    //     key: 'alt 7',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt7, description: '' }); }
    //   },
    //   {
    //     key: 'alt 8',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt8, description: '' }); }
    //   },
    //   {
    //     key: 'alt 9',
    //     command: () => { this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Alt9, description: '' }); }
    //   },
    //   {
    //     key: 'ctrl shift f',
    //     command: () => console.log('ctrl + shift + f'),
    //   },
    //   {
    //     key: 'cmd f',
    //     command: () => console.log('cmd + f'),
    //     preventDefault: true
    //   }
    // ]);
  }

  ngOnDestroy(): void {
    // this.keyboard.ngOnDestroy();
  }

}
