import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  animations: [
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ height: '!', opacity: 1 }),
        animate(600, style({ height: 0, opacity: 0 }))
      ]),
      transition('void => *', [
        style({ height: 0, opacity: 0 }),
        animate(700, style({ height: '*', opacity: 1 }))
      ])
    ])
  ]
})
export class FileUploadComponent implements OnInit {
  @Input() multiple = false;
  @Input() emitOnly = false;
  @Input() files: File[] = [];
  @Input() chooseLabel = 'Choose';
  @Input() uploadLabel = 'Upload';
  @Input() cancelLabel = 'Cancel';
  @Input() maxFileSize: number;
  @Input() showUpdateRemoveAll = false;
  @Input() httpUrl = '';
  @Input() acceptFormat = 'image/*,.pdf';
  @Input() acceptdocType = '';
  @Input() invalidFileSizeMessageSummary: string;
  @Input() requestObject: any; // Eg: { poNo : 1 }
  @Input() userNote = '';
  // @Input() httpRequestParams: HttpParams | { [param: string]: string | string[]; } = new HttpParams();
  @Input() httpRequestHeaders: HttpHeaders | { [header: string]: string | string[]; } = new HttpHeaders().set("Content-Type", "multipart/form-data");
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('fileUploadEmit', { static: false }) fileUploadEmit: ElementRef;
  @Input() fileAlias: string = "file";
  @Output() onUploadCompleted = new EventEmitter();
  fileName = '';
  // @Output() emitFile = new EventEmitter<{ req: any, outFile: File, message: String }>();

  constructor(private sanitizer: DomSanitizer, private httpClient: HttpClient) { }

  ngOnInit() { }

  inputFileName: string;
  public loaded: number = 0;
  isUploadAll: boolean = false;
  onClick(event) {
    if (!this.emitOnly) {
      if (this.fileUpload)
        this.fileUpload.nativeElement.click()
    } else {
      if (this.fileUploadEmit)
        this.fileUploadEmit.nativeElement.click()
    }
  }

  onFileSelected(event) {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    console.log('event::::::', event)
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      
      if (this.validateFileSize(file)) {
        if (this.validate(file)) {
          file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
          if (!this.isMultiple()) {
            this.files = []
          }
          this.files.unshift(files[i]);
        }
      }
    }
  }

  onEmitFileSelected(event) 
  {
    
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
   
     
    if(files!=null)
    {
      let resp;
      if (files && files.length > 0) 
      {      
      
      
      if (this.validateFileType(files[0])) {
        if (this.validateFileSize(files[0])) {
          this.fileName = files[0].name;
          resp = { reqObj: this.requestObject, outFile: files[0], message: "Done" };
          this.fileName = '';
          this.inputFileName = '';
        }
        else {
          resp = { reqObj: this.requestObject, outFile: null, message: "Please upload the Image file up to 5 MB file size !!" };
        }
      }
      else {
        resp = { reqObj: this.requestObject, outFile: null, message: "Please upload image File Only !!" };
      }

      // if (this.validateFileSize(files[0])) {
      //   if(this.validateFileType(files[0])){
      //     this.fileName = files[0].name;
      //     resp = { reqObj: this.requestObject, outFile: files[0], message: "Done" };
      //     this.fileName = '';
      //     this.inputFileName='';
      //   }
      //   else {
      //     resp = { reqObj: this.requestObject, outFile: null, message: "Please upload image File Only !!" };
      //   }
      //   // console.log(files[0]);
      //   // console.log(files[0].name);

      // } else {
      //   resp = { reqObj: this.requestObject, outFile: null, message: "Please upload the Image file up to 5 MB file size !!" };
      // }
 
      this.onUploadCompleted.emit(resp);

      if (this.files)
      {
        this.fileName = '';
        this.inputFileName = '';
      }
      }
      resp = { reqObj: this.requestObject, outFile: null, message: "Please upload at least one Store Location Image File !!" };}
    }

    validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name
        && f.lastModified === file.lastModified
        && f.size === file.size
        && f.type === file.type
      ) {
        return false
      }
    }
    return true
    }

    validateFileType(file: File) {
    let retbool = false;
    if (this.acceptdocType != null && this.acceptdocType != undefined) {
      if (file.type != null && file.type != undefined) {
        if (file.type.includes(this.acceptdocType)) {
          retbool = true;

        }
      } else {
        retbool = false;
      }
    } else {
      retbool = true;
    }
    return retbool;
  }
  validateFileSize(file: File) {
    return (this.formatBytes(file.size) > this.maxFileSize) ? false : true;
  }

  remove(index) {
    if (this.files)
      this.files.splice(index, 1);
  }

  isMultiple(): boolean {
    return this.multiple
  }

  uploadedFile(event) {
    this.onUploadCompleted.emit(event);
  }

  public uploadAll() {
    this.isUploadAll = true;
  }

  public removeAll() {
    this.files.splice(0, this.files.length);
  }
  formatBytes(bytes, decimals = 2) {
    // if (bytes === 0) return '0 Bytes';

    // const k = 1024;
    // const dm = decimals < 0 ? 0 : decimals;
    // // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    // const i = Math.floor(Math.log(bytes) / Math.log(k));

    // return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    var sizeInkb = (bytes / (1024)).toFixed(2);
    return parseFloat(sizeInkb);
  }
}
