import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { isNullOrUndefined } from 'util';
import { Logger, CommonService } from '../common-services/common-functions.service';
import { SharedService } from '../shared/shared.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router, private actRoute: ActivatedRoute, private sharedService: SharedService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // return true; // Prevent   To Check Rights
        // VALIDATE USER IS LOGGED IN OR NOT
        // console.log(route);
        if (this.auth.isAuthenticated) {
            // VALIDATE FLAG AGAINS USER THAT USER HAVE FULL RIGHTS . IF YES THEN NOT CHECKING RIGHTS FOR THAT USER
            if (this.auth.hasFullRight) {
                this.auth.pageAccessLevel = 127;
                return (true);
                //return Observable.of(true);
            } else {
                const roles = route.data["rights"] ? route.data["rights"] : 0;
                const rType = (route.params && route.params['type'] && Number(route.params['type']) > 0) ? Number(route.params['type']) : 0;
                if (rType > 0) {
                    return this.auth.validateUserRight(rType);
                } else {
                    if (!isNullOrUndefined(roles)) {
                        return this.ValidateRights(JSON.parse(roles), route.url, state.url);
                    } else {
                        Logger.Normal(state.url);
                        return this.ValidateRights(null, route.url, state.url);
                    }
                }
            }
        } else {
            this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }

    private ValidateRights(roles: any[], url, fullUrl): Observable<boolean> {
        if (!isNullOrUndefined(roles)) {
            // const path = url.reverse()[0]['path'];
            const parameter = 0; // (!isNullOrUndefined(path)  && !isNaN(Number(path))) ? Number(path) : 0;
            if (roles[parameter] > 0) {
                return this.auth.validateUserRight(roles[parameter]);
            } else if (roles[parameter] == 0) {
                return Observable.of(true);
            } else if (!isNullOrUndefined(fullUrl)) {
                return this.auth.validateUserRight(null, fullUrl.replace('/cpos', '.'));
            } else {
                console.log(fullUrl);
                return Observable.of(false);
            }
        } else if (!isNullOrUndefined(fullUrl)) {
            return this.auth.validateUserRight(null, fullUrl.replace('/cpos', '.'));
        } else {
            console.log(fullUrl);
            return Observable.of(false);
        }
    }


    // private ValidateRights(roles: any, url, fullUrl): Observable<boolean> {
    //     let path = '';
    //     let parameter;
    //     if (!isNullOrUndefined(roles) && roles[0] > 0) {
    //         parameter = 0;
    //     } else {
    //         if (url.length > 0) {
    //             path = url.reverse()[0]['path'];
    //         }
    //         parameter = (!isNullOrUndefined(path) && !isNaN(Number(path))) ? Number(path) : 0;
    //     }
    //     if (!isNullOrUndefined(roles)) {
    //         if (roles[parameter] === 0) {
    //             return Observable.of(true);
    //         } else if (roles[parameter] > 0) {
    //             return this.auth.validateUserRight(roles[parameter]);
    //         } else {
    //             Logger.Info(fullUrl);
    //             return Observable.of(false);
    //         }
    //     } else if (!isNullOrUndefined(fullUrl)) {
    //         return this.auth.validateUserRight(null, fullUrl.replace('/cpos', '.'));
    //     } else {
    //         Logger.Info(fullUrl);
    //         return Observable.of(false);
    //     }
    // }


}
