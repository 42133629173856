import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SnackBarService } from '../../common-services/snack-bar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse, CommonFilter } from '../../common-services/models.service';
import { CPOSEnum } from '../../common-services/enum.service';
import { SharedService } from '../../shared/shared.service';
import { CommonService } from '../../common-services/common-functions.service';
import { FormServices } from '../../common-services/validators.service';

@Component({
  selector: 'app-email-scheduler-component',
  templateUrl: './email-scheduler-component.component.html',
  styleUrls: ['./email-scheduler-component.component.css']
})
export class EmailSchedulerComponentComponent implements OnInit {
  emailSchedularForm: FormGroup;
  emaildata :any;
  filtervalue : CommonFilter;
  showfiltervalue : CommonFilter;
  chipObject ={};
  page : CPOSEnum;

  constructor(public dialogRef: MatDialogRef<EmailSchedulerComponentComponent>,
    private notification: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private sharedService: SharedService) {
      this.emaildata = data.searchdata;
      this.chipObject = data.chipDisplay;
      this.filtervalue =  data.showDisplay.filterArray;
    
      this.page = data.searchdata.currentPage;
      this.bindForm(null);
    
    this.binddata(this.page);
   

  }

  ngOnInit() {
  }
  bindForm(hasVal) {
    this.emailSchedularForm = this.fb.group({
      'pageId' :   this.page,
      'ES_FileName': [hasVal ? hasVal.eS_FileName: null ],
      'ES_To':  [hasVal ? hasVal.eS_To : null],
      'ES_CC': [hasVal ? hasVal.eS_CC : null],
      'ES_Subject': [hasVal ? hasVal.eS_Subject: null ],
      'ES_Body': [(hasVal ? hasVal.eS_Body : null),Validators.required],
      'ES_TriggerTime': [(hasVal ? hasVal.eS_TriggerTime : null),Validators.required]
    });
  }
 
  binddata(pageId : Number)
  {
    this.sharedService.customGetApi("emailschedule/getreportmail?pageid="+pageId ).subscribe((t)=>{
      if (t.status && t.data != null)
      {
         this.bindForm(t.data[0]);
      }
    });
  }
  onSave() {
    if (this.emailSchedularForm.valid) {
      const saveObj = CommonService.copyObject(this.emailSchedularForm.value);
      saveObj.FilterArray = this.emaildata;
      this.sharedService.customPostApi('emailschedule/saveupdate', saveObj).subscribe((t: ApiResponse) => {
        if (t.status === true) {
          this.notification.success('Save Email Scheduler Successfully');
          this.dialogRef.close();
        } else {
          this.notification.warning(t.message);
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      FormServices.focusFormValidationErrors(this.emailSchedularForm);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  

}
