import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { isNullOrUndefined } from 'util';
import { Documents } from '../../common-services/models.service';
import { SharedService } from '../../shared/shared.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../common-services/common-functions.service';

@Component({
  selector: 'app-image-list-preview',
  templateUrl: './image-list-preview.component.html',
  styleUrls: ['./image-list-preview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageListPreviewComponent implements OnInit {

  @Input() boxHeight = 500;
  @Input() isPreviewFirst = false;

  imageFileList = new Array<Documents>();
  docFileList = new Array<Documents>();
  
  pageProperties = { previewImage: null, selectedDoc: null, showProgress: false };
  private imageFormat = ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'jfif'];
  //private videoFormat = ['mp4'];

  @Input() set docuementList(val: Documents[]) {
    if (val) {
      this.docFileList = this.imageFileList = [];
      this.pageProperties.selectedDoc = null;
      this.imageFileList = this.applyThumbnail(val, 'images');
      this.docFileList = this.applyThumbnail(val, 'files');
      //this.VideofielList= this.applyThumbnail(val, 'videos');
      if (this.isPreviewFirst) {
        this.onPreviewImgeClick(val[0]);
      }
    }
  };

  @Input() apiUrl: string;
  @Input() ContainsImageurl: boolean;
  @Output() viwedDocument = new EventEmitter<any>();
  isPopup = false;

  constructor(private sharedService: SharedService, public dialogRef: MatDialogRef<ImageListPreviewComponent>) { }
  ngOnInit() { }
  applyThumbnail(resp: any, typeOfList: string): any {
    let extension = '';
    return resp.filter((t) => {
      if (!isNullOrUndefined(t.documentName) && t.documentName.lastIndexOf(".") >= 0) {
        extension = t.documentName.substring(t.documentName.lastIndexOf(".") + 1);
        t.thumbnail = this.getClassName(extension);
        t.fileType = this.getFileType(extension);
      } else {
        t.thumbnail = '/assets/images/nofile.png';
        t.fileType = 'ohter';
        t.fileName = '<span style="color:#b3b3b3">Not Available<span>'
      }
      if (typeOfList == 'images' && this.imageFormat.indexOf(extension) >= 0) {
        return t;
      }
      if (typeOfList == 'files' && (this.imageFormat.indexOf(extension) == -1 )) {
        return t;
      }
      // && this.videoFormat.indexOf(extension) == -1
      // if (typeOfList == 'videos' && this.videoFormat.indexOf(extension) == 0) {
      //   return t;
      // }
      

    });
  }

  getClassName(fileExtension) {
    switch (fileExtension.toLowerCase()) {
      case 'xlsx':
      case 'xls':
      case 'csv':
        return "/assets/images/xls.png";
      case 'docx':
      case 'doc':
        return "/assets/images/doc.png";
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'tiff':
        return "/assets/images/jpeg.png";
      case 'rar':
      case 'zip':
        return "/assets/images/zip.png";
      case 'mp4':
        return "/assets/images/Videologo.jpg";     
      case 'png':
        return "/assets/images/png.png";
      case 'txt':
        return "/assets/images/txt.png";       
      default:
        return '/assets/images/' + fileExtension + '.png';
    }
  }

  private getFileType(extenstion: string) {
    switch (extenstion.toUpperCase()) {
      case "JPG":
      case "JPEG":
      case "PNG":
      case "GIF":
      case "TIFF":
      case "JFIF":
        return "image"
      case "PDF":
        return "pdf"
      case "mp4":
        return "mp4"
      default:
        return 'other';
    }
  }

  onPreviewImgeClick(doc) {
    this.pageProperties.showProgress = true;
    this.pageProperties.selectedDoc = CommonService.copyObject(doc);
    this.pageProperties.selectedDoc.hidePreviewButton = true;
    if(this.ContainsImageurl==false)
    {
      this.sharedService.previewImage(this.apiUrl, doc.documentPath).subscribe((t) => {
        this.viwedDocument.emit(doc);
        this.createImageFromBlob(t.body);
        this.pageProperties.showProgress = false;
      }, (error) => {
        console.error(error);
        if (error && error.status == 404) {
          this.pageProperties.selectedDoc = null;
        }
        this.pageProperties.showProgress = false;
      });
    }
    else
    {
      this.pageProperties.previewImage = doc.documentPath;
      this.pageProperties.showProgress = false;
    }
    
  }


  createImageFromBlob(image: Blob) {
    try {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        this.pageProperties.previewImage = reader.result;
      }, false);
  
      if (image) {
        reader.readAsDataURL(image);
      }  
    } catch (error) {
      
    }
  }

  onDownloadFile(doc) {
    if (!isNullOrUndefined(doc)) {
      if(this.ContainsImageurl==false)
      {
        this.sharedService.previewImage(this.apiUrl, doc.documentPath).subscribe((t) => {
          if (t != null) {
            saveAs(new Blob([t.body]), doc.documentName);
          }
        });
      }
      else 
      {
        window.open(doc.documentPath, '_blank');
        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');
        // link.setAttribute('href', doc.documentPath);
        // link.setAttribute('download', doc.documentName);
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        //saveAs(doc.documentPath, doc.documentName);
      }
      
    }
  }
  onCancelClick() {
    this.dialogRef.close();
  }
}
