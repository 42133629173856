import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponentComponent } from '../common-component/snackbar-component/snackbar-component.component';
import { ChangeHistoryComponent } from '../common-component/change-history/change-history.component';
import { CommonService } from './common-functions.service';

@Injectable()
export class SnackBarService {

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    autoHide: number = 3000;
    config: any;
    constructor(private snackBar: MatSnackBar) {
        this.config = this.getConfig() as MatSnackBarConfig;
    }

    success(message, title = '', icon = 'check') {
        this.config.panelClass = [`success-bar`];
        let instance: MatSnackBarRef<SnackbarComponentComponent> = this.getInstance();
        instance.instance.messageType = 'success';
        instance.instance.icon = icon;
        instance.instance.title = title;
        instance.instance.message = message;
    }

    warning(message, title = '', icon = 'warning') {
        // this.snackBar.open(message, undefined, config);
        this.config.panelClass = [`warning-bar`];
        let instance: MatSnackBarRef<SnackbarComponentComponent> = this.getInstance();
        instance.instance.messageType = 'warning';
        instance.instance.icon = icon;
        instance.instance.title = title;
        instance.instance.message = message;
    }

    info(message, title = '', icon = 'info') {
        // this.snackBar.open(message, undefined, config);
        this.config.panelClass = [`info-bar`];
        let instance: MatSnackBarRef<SnackbarComponentComponent> = this.getInstance();
        instance.instance.messageType = 'info';
        instance.instance.icon = icon;
        instance.instance.title = title;
        instance.instance.message = message;
    }


    error(message, title = '', icon = 'error') {
        // this.snackBar.open(message, undefined, config);
        this.config.panelClass = [`error-bar`];
        let instance: MatSnackBarRef<SnackbarComponentComponent> = this.getInstance();
        instance.instance.messageType = 'success';
        instance.instance.icon = icon;
        instance.instance.title = title;
        instance.instance.message = message;
    }

    private getConfig() {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.autoHide;
        config.panelClass = undefined;
        return config;
    }

    private getInstance(): MatSnackBarRef<SnackbarComponentComponent> {
        return this.snackBar.openFromComponent(SnackbarComponentComponent, this.config);
    }
}

@Injectable()
export class HistoryService {

    constructor(private dialog: MatDialog) {
    }

    openHistory(_tableName: string, _id: string) {
        const dialogRef = this.dialog.open(ChangeHistoryComponent, {
            width: String(CommonService.mainSectionLW.width - 500) + 'px',
            height: String(CommonService.mainSectionLW.height - 200) + 'px',
            data: { tableName: _tableName, id: _id },
            hasBackdrop: true,
            disableClose: true,
            ariaLabel: 'history-popup',
            ariaDescribedBy: 'Chagnge History',
            closeOnNavigation: true
        });
    }
    CloseHistory()
    {
        this.dialog.ngOnDestroy();
    }

}