import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { SnackBarService } from '../../common-services/snack-bar.service';
import { FormServices, PasswordValidator } from '../../common-services/validators.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  IsLogout = false;
  registerForm: FormGroup;
  UserId = '';
  userData: any;
  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private authService: AuthService,
    private notification: SnackBarService,
    private fb: FormBuilder) {
    this.UserId = data.selectedUser.id;
    this.userData = data.selectedUser;
    this.IsLogout = data.isLogOut;
    if (this.IsLogout == false) {
      this.registerForm = this.fb.group({
        id: [this.UserId],
        newPassword: [null, Validators.compose([Validators.required, PasswordValidator.strong])],
        confirmPassword: [null, Validators.required],
      }, { validator: this.checkIfMatchingPasswords('newPassword', 'confirmPassword') });
    } else {
      this.registerForm = this.fb.group({
        id: [null],
        oldPassword: [null, Validators.required],
        newPassword: [null, Validators.compose([Validators.required, PasswordValidator.strong])],
        confirmPassword: [null, Validators.required],
      }, { validator: this.checkIfMatchingPasswords('newPassword', 'confirmPassword') });
    }
  }

  ngOnInit() {

  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  onSubmit() {
    if (this.registerForm.valid) {
      this.registerForm.value.id = this.UserId;
      this.sharedService.customPostApi('Account/ChangePassword', this.registerForm.value).subscribe((t) => {
        this.notification.success('Password Change Successfully');
        this.registerForm.reset();
        if (this.IsLogout === true) {
          this.authService.logOut();
        }
        this.dialogRef.close(true);
      }, (error) => {
        this.notification.success('Password Change failed');
      });
    } else {
      FormServices.focusFormValidationErrors(this.registerForm);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

}
