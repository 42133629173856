import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { filter } from 'rxjs-compat/operator/filter';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiResponse } from '../../../common-services/models.service';
import { CommonService, DateService } from '../../../common-services/common-functions.service';
import { SharedService } from '../../../shared/shared.service';
import { SnackBarService } from '../../../common-services/snack-bar.service';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-export-selection',
  templateUrl: './export-selection.component.html',
  styleUrls: ['./export-selection.component.css']
})
export class ExportSelectionComponent implements OnInit {
  ReportRequestDetailForm: FormGroup;
  allField = [];
  selectedFields = [];
  boxHeight = 0;
  RequestReportId = 0;
  RequestExportList = "";
  RequestFilterList = "";
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ExportSelectionComponent>,
    public sharedService: SharedService,
    // public sharedService: SharedService, 
    public notification: SnackBarService,
    @Inject(MAT_DIALOG_DATA) private confModel: any) {
    if (confModel) {
      this.allField = confModel.source.filter((t) => t.isDisplay == false);
      this.selectedFields = confModel.source.filter((t) => t.isDisplay == true);
      console.log(confModel);
      this.RequestReportId = confModel.source[0].pageId;
      this.boxHeight = confModel.height;
      let isDate = false;


      if (!isNullOrUndefined(this.confModel.filterrequestoption)) {
        if (this.confModel.filterrequestoption.length > 0) {
          for (let i = 0; i < this.confModel.filterrequestoption.length; i++) {
            isDate = !!confModel.source.find((t) => t.displayName + '_from' == this.confModel.filterrequestoption[i].key || t.displayName + '_to' == this.confModel.filterrequestoption[i].key);
            //~~~~~~~~~~~~~~ Tushar 08-01-2024 Gid.: 601 and 371
            //this.RequestFilterList += `${this.confModel.filterrequestoption[i].key}=${(isDate ? DateService.formatDate(this.confModel.filterrequestoption[i].value, "YYYY-MM-DD") : this.confModel.filterrequestoption[i].value)},`;
            this.RequestFilterList += `${this.confModel.filterrequestoption[i].key}=${(isDate ? DateService.formatDate(this.confModel.filterrequestoption[i].value, "YYYY-MM-DD") : this.confModel.filterrequestoption[i].value.split(',').join('|'))},`;
            //~~~~~~~~~~~~~~ Gid.: 601 and 371
            //this.RequestFilterList += `${this.confModel.filterrequestoption[i].key}=${(isDate ? DateService.getUTCDate(this.confModel.filterrequestoption[i].value) : this.confModel.filterrequestoption[i].value)},`;
          }
          this.RequestFilterList = this.RequestFilterList.substring(0, this.RequestFilterList.length - 1);
          console.log(this.RequestFilterList);
        }

      }

      this.BindForm();
    }
  }

  ngOnInit() {

  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onOkClick() {
    if (this.selectedFields.length > 0) {
      this.dialogRef.close(this.selectedFields);
    } else {
      this.notification.warning('Please select atleast one field for export to excel.');
    }
  }

  BindForm() {
    this.ReportRequestDetailForm = this.fb.group({
      'reportName': this.confModel.source[0].pageId,
      'filterColumn': this.RequestFilterList,
      'exportcolumn': [null],

    });
  }
  OnRequestClick() {
    
    if (this.selectedFields.length > 0) {
      for (let i = 0; i < this.selectedFields.length; i++) {
        if (this.RequestExportList != "") {
          this.RequestExportList += "," + this.selectedFields[i].tableFieldName;
        }
        else {
          this.RequestExportList = this.selectedFields[i].tableFieldName;
        }
      }

      this.ReportRequestDetailForm.get('exportcolumn').setValue(this.RequestExportList);

      const saveObj = CommonService.copyObject(this.ReportRequestDetailForm.value);
      this.sharedService.customPostApi('reportrequest/save', saveObj).subscribe((t: ApiResponse) => {
        if (t.status === true) {
          // this.sharedService.customGetApi('common/generatecsvreport?id=' + t.data).subscribe(async (t: ApiResponse) => {
          // });
          this.notification.success('Report request has been received!');
        } else {
          this.notification.warning(t.message);
        }

      }, (err) => {
        console.log(err);
      });
      this.RequestExportList = "";
      this.dialogRef.close();
    }
  }

}
