import { isNullOrUndefined, isDate } from "util";
import * as moment from 'moment';

export class DateService {
    public static getUTCDate(dt: any): any {
        try {
            var utc = moment(dt, "YYYY-MM-DDTHH:mm:ssZ", true);
            var isUTC = utc.isValid();
            if (!isUTC) {
                if (!isNullOrUndefined(dt) && isDate(dt)) {
                    var m = moment(dt);
                    return m.utcOffset("+05:30").format();
                }
            } else {
                return utc.utcOffset("+05:30").format();
            }
        } catch (error) {
            Logger.Error(error);
            return dt;
        }
    }

    public static formatDate(dt: any, format = 'DD/MM/YYYY') {
        try {
            let d = new Date(dt);
            return moment(d).format(format);
        } catch (error) {
            Logger.Error(error);
            return dt;
        }
    }
    public static formatDateWise(dt: any, format = 'MM/DD/YYYY') {

        try {
            let d = new Date(dt);
            return moment(d).format(format);
        } catch (error) {
            Logger.Error(error);
            return dt;
        }
    }
    public static getDateFormate(dt: any, format = 'YYYY-MM-DD') {
        try {
            let d = new Date(dt);
            return moment(d).format(format);
        } catch (error) {
            Logger.Error(error);
            return dt;
        }
    }
}

export class CommonService {


    public static getColumnAsArray(arrObj, columnName: string) {
        if (!isNullOrUndefined(arrObj) && columnName !== '') {
            return arrObj.map(a => a[columnName]);
        } else {
            return [];
        }
    }
    public static getMatColumnAsArray(arrObj, columnName: string) {
        if (!isNullOrUndefined(arrObj) && columnName !== '') {
            return arrObj.filter((t) => t.isDisplay == true).map(a => a[columnName]);
        } else {
            return [];
        }
    }

    public static getMatColumnAsVisibilityArray(arrObj) {
        if (arrObj != null && arrObj != undefined) {
            return arrObj.filter((t) => t.isDisplay == true).map(a => {
                let obj = {};
                obj['displayName'] = a.displayName;
                obj['headerName'] = a.headerName;
                obj['isDisplay'] = a.isDisplay;
                obj['fieldType'] = a.fieldType;
                return obj;
            });
        } else {
            return [];
        }
    }

    public static getControlSizeById(controlId: string): any {
        if (!isNullOrUndefined(controlId) && controlId !== '') {
            if (!isNullOrUndefined(document.getElementById(controlId))) {
                return { height: document.getElementById(controlId).offsetHeight, width: document.getElementById(controlId).offsetWidth }
            } else {
                return { height: 0, width: 0 }
            }
        } else {
            return { height: 200, width: 200 }
        }
    }

    public static get mainSectionLW() {
        return this.getControlSizeById('mainSection');
    }

    public static focusControlById(controlId: string, time = 100) {
        if (!isNullOrUndefined(controlId) && controlId !== '') {
            setTimeout(() => {
                if (!isNullOrUndefined(document.getElementById(controlId))) {
                    document.getElementById(controlId).focus();
                }
            }, time);
        }
    }

    public static getKeyName(obj) {
        return (!isNullOrUndefined(obj)) ? Object.keys(obj)[0] : '';
    }

    public static AddLink(_link: any, _title) {
        try {
            if (_link && _title) {
                const encodeVal = localStorage.getItem('vl');
                if (encodeVal) {
                    let visitLinks = [];
                    visitLinks = JSON.parse(atob(encodeVal));
                    if (visitLinks.find(t => t.link == _link)) {
                        visitLinks = visitLinks.filter((t) => {
                            if (t.link == _link) {
                                t.count = t.count + 1;
                            }
                            return t;
                        });
                    } else {
                        visitLinks.push({ 'link': _link, title: _title, count: 1 });
                    }
                    localStorage.setItem('vl', btoa(JSON.stringify(visitLinks)));
                } else {
                    localStorage.setItem('vl', btoa(JSON.stringify([{ 'link': _link, title: _title, count: 1 }])));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    public static regExPatern = {
        mobnum: "^((\\+91-?)|0)?[0-9]{10}$",
        number: '^(0|[1-9][0-9]*)$',
        gstnum: '^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$',
        decimal: "^[0-9]{1,12}(\.[0-9]{0,2})?$",
        usernamePattern: '^[a-z0-9_-]{8,15}$',
        pwdPattern: '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[!@#$%^&*()_+=\[{\]};:<>|?,-]).{6,32}$',
        mobilePattern: '^((\\+91-?)|0)?[0-9]{10}$',
        datepattern:" (^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)",

    }

    // public static get defaultComanyId(): number {
    //     const no = localStorage.getItem('cc');
    //     return (!isNullOrUndefined(no) ? Number(no) : 0);
    // }

    // public static set defaultComanyId(v: number) {
    //     localStorage.setItem('cc', String(v))
    // }

    // public static get defaultYearKey(): number {
    //     const no = localStorage.getItem('yk');
    //     return (!isNullOrUndefined(no) ? Number(no) : 0);
    // }

    // public static set defaultYearKey(v: number) {
    //     localStorage.setItem('yk', String(v))
    // }

    public static validateNumber(value) {
        if (!isNullOrUndefined(value) && !isNaN(Number(value))) {
            return Number(value);
        } else {
            return 0;
        }
    }

    public static copyObject(v) {
        if (!isNullOrUndefined(v)) {
            return JSON.parse(JSON.stringify(v));
        } else {
            return v;
        }
    }

}

export class CommonMessages {
    static account_validation_messages = {
        'username': [
            { type: 'required', message: 'Username is required' },
            { type: 'minlength', message: 'Username must be at least 5 characters long' },
            { type: 'maxlength', message: 'Username cannot be more than 25 characters long' },
            { type: 'pattern', message: 'Your username must contain only numbers and letters' },
            { type: 'validUsername', message: 'Your username has already been taken' }
        ],
        'email': [
            { type: 'required', message: 'Email is required' },
            { type: 'pattern', message: 'Enter a valid email' }
        ],
        'confirm_password': [
            { type: 'required', message: 'Confirm password is required' },
            { type: 'areEqual', message: 'Password mismatch' }
        ],
        'password': [
            { type: 'required', message: 'Password is required' },
            { type: 'minlength', message: 'Password must be at least 5 characters long' },
            { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
        ],
        'terms': [
            { type: 'pattern', message: 'You must accept terms and conditions' }
        ]
    }
}

export class Logger {
    public static loggingEnabled = false;

    public static Normal(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.log(message, key);
        }
    }

    public static Error(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.error(message, key);
        }
    }

    public static Warn(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.warn(message, key);
        }
    }

    public static Info(message: any, key: string = '') {
        if (this.loggingEnabled) {
            console.info(message, key);
        }
    }
}

