import { Component, OnInit, Input, ViewChild } from '@angular/core';
// import { } from 'googlemaps';
import { SharedService } from '../../shared/shared.service';
import { MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-map-control',
  templateUrl: './map-control.component.html',
  styleUrls: ['./map-control.component.css']
})
export class MapControlComponent implements OnInit {
  marker: any[];
  lt: any;
  lg: any;
  crcleRadius: number;
  @Input() set centerLatitude(lat: any) {
    if (lat) {
      this.lt = lat;
    }
  }
  @Input() set centerLongitude(lng: any) {
    if (lng) {
      this.lg = lng;
    }
  }
  @Input() set circleRadius(rds: number) {
    if (rds) {
      this.crcleRadius = rds;
    }
  }
  mpmarkerlist: any[];
  @Input() set mapmarkerlist(val: any[]) {
    if (val) {
      this.mpmarkerlist = val;
      // this.docFileList = this.imageFileList = [];
      // this.pageProperties.selectedDoc = null;
      // this.imageFileList = this.applyThumbnail(val, 'images');
      // this.docFileList = this.applyThumbnail(val, 'files');
      // //this.VideofielList= this.applyThumbnail(val, 'videos');
      // if (this.isPreviewFirst) {
      //   this.onPreviewImgeClick(val[0]);
      // }
    }
  }
  uluru: Object = { lat: 22.2868828, lng: 73.1642781 };
  @ViewChild('gmap', { static: false }) gmapElement: any;
  map: google.maps.Map;
  constructor(private sharedService: SharedService, public dialogRef: MatDialogRef<MapControlComponent>) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    
    const mapProp = {
      center: new google.maps.LatLng(this.lt, this.lg),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    if (this.crcleRadius && this.crcleRadius>0) {


      const circle = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: this.map,
        center: new google.maps.LatLng(this.lt, this.lg),
        radius: this.crcleRadius,
      });
    }
    setTimeout(() => {
      // this.map = new google.maps.Map(this.gmapElement.nativeElement, {
      //   zoom: 15,
      //   center: new google.maps.LatLng(22.2868828, 73.1642781)
      // });
      for (let index = 0; index < this.marker.length; index++) {
        const element = this.marker[index];
        const mark = new google.maps.Marker({
          position: new google.maps.LatLng(element.lat, element.lng),
          map: this.map,
          label: element.storeNo,
          title: element.storeName,
          animation: google.maps.Animation.DROP,
          icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
          // icon:'https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png'
          // draggable:true
        });
        // mark.addListener('click', () => { this.infowindow.open(this.map, mark) })
      }
      // this.marker = new google.maps.Marker({
      //   position: new google.maps.LatLng(22.2868828, 73.1642781),
      //   map: this.map
      // });
    }, 2000);
  }
  // infowindow = new google.maps.InfoWindow({
  //   content: 'Test',
  // });
  onCancelClick() {
    this.dialogRef.close();
  }

}
