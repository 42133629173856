
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    private authService: AuthService;

    constructor(private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.url.startsWith('/assets')) {
            const url = request.url;
            this.authService = this.injector.get(AuthService); // get it here within intercept

            if (!request.url.startsWith(this.authService.AppConfig.lcURL))
                request = request.clone({ url: `${this.authService.AppConfig.apiURL}${request.url}` });
            // request = request.clone({ url: `${this.authService.AppConfig.apiURL}${request.url}` });

            if (request.url.indexOf('token') >= 0) {
                return next.handle(request);
            }
            // add it if we have one
            if (this.authService.isAuthenticated) {
                const token: string = this.authService.getToken; // get it here within interceptoken();
                // if (!request.url.startsWith('http://localhost:1440/api/po/upload')) {
                request = request.clone({ headers: request.headers.set('Authorization', token) });
                // }
                // if this is a login-request the header is
                // already set to x/www/formurl/encoded.
                // so if we already have a content-type, do not
                // set it, but if we don't have one, set it to
                // default --> json
                if (!url.startsWith('common/upload')) {
                    if (!request.headers.has('Content-Type')) {
                        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                    }
                }
                // setting the accept header
                // return request;
                //request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
            }
        } else {
            console.log(request.url);
        }
        return next.handle(request).catch((error) => {
            // 
            if (error instanceof HttpErrorResponse) {
                switch ((<HttpErrorResponse>error).status) {
                    case 400: {
                        // this.handle400Error(error);
                        return Observable.throw(error);
                    }
                    case 401: {
                        // this.handle401Error(request, next);
                        this.authService.logOut();
                        // not logged in so redirect to login page with the return url and return false
                        return Observable.throw(error);
                    }
                    default: {
                        return Observable.throw(error);
                    }
                }
            } else {
                return Observable.throw(error);
            }
        });
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        // if (!this.isRefreshingToken) {
        //   this.isRefreshingToken = true;

        //   // Reset here so that the following requests wait until the token
        //   // comes back from the refreshToken call.
        //   this.tokenSubject.next(null);

        //   return this.authService.refreshToken()
        //     .switchMap((newToken: string) => {
        //       if (newToken) {
        //         this.tokenSubject.next(newToken);
        //         return next.handle(this.addToken(this.getNewRequest(req), newToken));
        //       }

        //       // If we don't get a new token, we are in trouble so logout.
        //       return this.logoutUser();
        //     })
        //     .catch(error => {
        //       // If there is an exception calling 'refreshToken', bad news so logout.
        //       return this.logoutUser();
        //     })
        //     .finally(() => {
        //       this.isRefreshingToken = false;
        //     });
        // } else {
        //   return this.tokenSubject
        //     .filter(token => token != null)
        //     .take(1)
        //     .switchMap(token => {
        //       return next.handle(this.addToken(this.getNewRequest(req), token));
        //     });
        // }
    }
}
