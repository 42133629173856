import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Rx";
import { AppInitialize } from "../app-init.service";
import { WebsocketService } from "./websocket.service";

export interface Message {
  author: string;
  message: string;
}

@Injectable()
export class CommunicationService {
  public messages: Subject<Message>;

  constructor(private wsService: WebsocketService, private appInit: AppInitialize) {
  }

  connectWebSocket(userName) {
    this.messages = <Subject<Message>>this.wsService.connect(this.appInit.wcURL + this.appInit.applicationID + '&un=' + userName).map(
      (response: MessageEvent): Message => {
        let data = JSON.parse(response.data);
        console.log(data, '<--- Message');
        return {
          author: data.author,
          message: data.message
        };
      }
    );
  }

  disconnectSocket() {
    this.wsService.close();
    if (this.messages) {
      this.messages.unsubscribe();
    }
  }
}
// https://github.com/PeterKassenaar/ng2-websockets