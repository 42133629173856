import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarRef } from '@angular/material';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SnackBarService } from './snack-bar.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateService } from './common-functions.service';

@Injectable()
export class ExcelService {
  observable: any;

  constructor(private notification: SnackBarService,public dialog: MatDialog, private spinner: NgxSpinnerService) { }

  public exportAsExcelFiles(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    this.spinner.hide();
  }
  public exportDatatoExcel(data: any, dataArr: any, reportName: string) {
    const arr = [];
    data.forEach((t: { [x: string]: any; }) => {
      let dt = {};
      dataArr.forEach((element: { headerName: string | number; displayName: string | number; }) => {
        dt[element.headerName] = t[element.displayName];
      });
      arr.push(dt);
    });
    this.exportAsExcelFiles(arr, ((reportName) ? reportName : 'report'));
  }

  ArangeData(APIData,columnsData){
    const arr = [];
    APIData.forEach(t => {
      let dt = {};
     
      columnsData.forEach(element => {
        if(element.fieldType==6){
            if(t[element.displayName] != '' && t[element.displayName] != null){
              if(t[element.displayName].split("-")[0].length===4)
              {
                dt[element.headerName] = new Date(t[element.displayName]);
              }
              else{
                dt[element.headerName] = t[element.displayName];
              }
            
            }
            else{
              dt[element.headerName] = t[element.displayName];
            }
        }
        //~~~~~~~~~~~~~~~~~~ Tushar 09-01-2023 Gid.:677
        else if (element.fieldType == 12)
        {
          if(t[element.displayName] != '' && t[element.displayName] != null){
            if(t[element.displayName].length===19)
            {
              dt[element.headerName] = DateService.formatDate(t[element.displayName],"DD/MM/YYYY HH:mm:ss");
            }
            else{
              dt[element.headerName] = t[element.displayName];
            }
          
          }
        }
        //~~~~~~~~~~~~~~~~~~ Gid.:677
        else{
          dt[element.headerName] = t[element.displayName];
        }
        
      });

      arr.push(dt);
    });
    return arr;
  }
}
