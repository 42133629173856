import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable()
export class NavService {
  isLogin = false;
  // restrictClose = false;
  public currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isLogin = event.urlAfterRedirects !== '/auth/login';
        this.currentUrl.next('.' + event.urlAfterRedirects);
      }
    });
  }


  public get restrictClose(): boolean {
    const val = localStorage.getItem('rc');
    return isNullOrUndefined(val) ? false : val === 'true';
  }


  public set restrictClose(v: boolean) {
    localStorage.setItem('rc', String(v));
  }


}

export interface NavItem {
  title: string;
  disabled?: boolean;
  icon: string;
  link?: string;
  children?: NavItem[];
}
