import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { SharedService } from '../../../shared/shared.service';
import { cellDef, TableObject } from '../../../common-services/models.service';

@Component({
  selector: 'app-sub-report-new',
  templateUrl: './report-popup.component.html',
  styleUrls: ['./report-popup.component.css']
})
export class ReportPopupComponent implements OnInit {
  reportObj: any;
  isSearching = false;
  PopUpKeyFieldValue: string;
  sectionHeight: number;
  tableObject: TableObject;
  dataSource = new MatTableDataSource<any>([]);
  constructor(public dialogRef: MatDialogRef<ReportPopupComponent>,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) private confModel: any) {
    if (confModel) {

      this.reportObj = confModel
      if (this.reportObj && this.reportObj.reportInfo) {
        this.PopUpKeyFieldValue = this.reportObj.PopUpKeyFieldValue;
        const cellArray: any = this.getTableCellArray(this.reportObj.tablerConfig);
        const FilterArray: any = this.sharedService.getSearchFilter(this.reportObj.reportInfo.pageId);
        this.tableObject = new TableObject(cellArray.tableCellArray, this.reportObj.reportInfo.viewButtonsSum, this.reportObj.reportInfo.isPagingEnable, null, FilterArray, this.reportObj.reportInfo.pageId);
        this.onFilterEvent({ filter: FilterArray });
      }
    }
  }

  ngOnInit() {
  }
  getTableCellArray(data: any): any {
    const _tableCellArray = [];
    if (data) {
      data.forEach(element => {
        _tableCellArray.push(new cellDef(element.tableFieldName, element.headerName, element.showFieldDDL, element.fieldType, element.footerFields, null, element.cellCss, element.fieldPrefix, element.subReportId, element.isDisplay));
      });
    }
    return { tableCellArray: _tableCellArray };
  }
  onFilterEvent(event) {
    let cellArray: any = {};
    cellArray = { ...event.filter, filterArray: [...event.filter.filterArray] };
    cellArray.filterArray.push({ key: "id", value: this.PopUpKeyFieldValue });
    this.sharedService.customPostApi('common/reportlist?type=' + this.reportObj.reportInfo.pageId, cellArray).subscribe((t: any) => {
      this.dataSource = new MatTableDataSource<any>(t.data);
    });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

}
