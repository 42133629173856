import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin } from 'rxjs';
import { isDate } from 'rxjs/internal/util/isDate';
import { debounceTime } from 'rxjs/operators';
import { isNullOrUndefined, isUndefined } from 'util';
import { AuthService } from '../../auth/auth.service';
import { CommonService } from '../../common-services/common-functions.service';
import { AccessLevel, InputType, ShortCutKeys } from '../../common-services/enum.service';
import { IFilterGridInput, IHashTable, IShortCutKeys } from '../../common-services/interface.service';
import { CommonFilter, TableObject, cellDef } from '../../common-services/models.service';
import { SnackBarService } from '../../common-services/snack-bar.service';
import { SharedService } from '../../shared/shared.service';
import { FilterGridComponent } from '../filter-grid/filter-grid.component';
import { ExportSelectionComponent } from './export-selection/export-selection.component';
import { SubReportComponent } from './sub-report/sub-report.component';
import { EmailSchedulerComponentComponent } from '../email-scheduler-component/email-scheduler-component.component';
@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MatTableComponent implements OnInit, OnDestroy {
  pageProperties = {
    currentpage: 0
  }
  disabledflg : any;
  displayedColumns: string[] = [];
  allvisiblecolumns: any[];
  visiblecolumns: any[];
  headerColumns: string[] = [];
  columnsDataType: InputType[] = [];
  searchFieldDDL = [];
  actionButton = AccessLevel;
  tableProperties: TableObject;
  footerDatasource = {};
  tooltipFields = new Map();
  cssFields = new Map();
  filterPlaceHolder = 'Filter';
  filterValidationError = '';
  selectedRow: any;
  selectedOn = '';
  autoList = [];
  filterObj = {
    field: new FormControl(null, Validators.required),
    value: new FormControl(null),
    autoSearchKey: new FormControl(null),
  };
  iconfilter = 'visibility_off';
  advanceSearchObject: IFilterGridInput;
  clsfilter: CommonFilter;
  clsShowfilter: CommonFilter;
  filterObject = {};
  chipDisplayObj = {};  
  @Input() tableHeight: number;
  @Input() showFilter = true;
  @Input() isSearching = false;
 
  @Input() showExport = true;
  @Input() edittoolTip: string;
  @Input() viewtoolTip: string;
  tableSource: MatTableDataSource<any>;
  private watcher: Subscription;
  private subscription: Subscription;
  private dialogRef: MatDialogRef<FilterGridComponent>;
  private dialogExportRef: MatDialogRef<ExportSelectionComponent>;
  private dialogSubReport: MatDialogRef<SubReportComponent>;
  private dialogSendEmail : MatDialogRef<EmailSchedulerComponentComponent>;
  hLRowId: 0;
  footerDisplay = false;
  tableFirstRow = null;
  @Input() set hightLightRowId(v: any) {
    this.hLRowId = v;
    setTimeout(() => {
      this.hLRowId = 0;
    }, 3200);
  }

  @Input() set dataSource(v: any) {
    if (v) {
      this.tableSource = v;
      this.footerDisplay = false;
      if (!isNullOrUndefined(this.tableProperties) && !this.tableProperties.isServerSidePaging) {
        this.tableSource.paginator = this.paginator;
        this.tableSource.sort = this.sort;
        this.tableSource.filterPredicate = this.createFilter;
        if (this.clsfilter.searchValue) {
          this.filterLocalEvent();
        }
      }
      if (this.tableSource.data && this.tableSource.data.length > 0) {
        this.tableFirstRow = this.tableSource.data[0];
      }

      if (this.tableProperties && this.tableFirstRow) {
        this.setDefaultFooter(this.tableProperties.cellDefArray, this.getCurrentPageData);
      }
    }
  };
  @Input() inProgress = false;
  @Input() invisibleDump = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() action = new EventEmitter<{ actionButon: AccessLevel, data: any }>();
  @Output() filter = new EventEmitter<{ filter: CommonFilter }>();
  @Input() set tableConfiguration(t: any) {
    console.log(t);
    if (!isNullOrUndefined(t) && this.tableSource) {
      this.pageProperties.currentpage = t.pageEnum;
      this.displayedColumns = CommonService.getMatColumnAsArray(t.cellDefArray, 'displayName');
      this.allvisiblecolumns = CommonService.getMatColumnAsVisibilityArray(t.cellDefArray);
      this.sharedService.customGetApi('common/getcolumnprefrence?Pageid=' + this.pageProperties.currentpage).subscribe(t => {
        if (t && t.status === true) {
          if (t.data) {
            this.visiblecolumns = t.data.visibleColumnsArray;
            if (this.visiblecolumns === null || this.visiblecolumns === undefined || this.visiblecolumns.length <= 0) {
              this.visiblecolumns = this.allvisiblecolumns.filter(x => x.isDisplay === true).map(a => a.displayName);
            }
            if (this.invisibleDump) {
              this.oncolumnVisibilityChanged(null);
            }
          }
          else {
            this.visiblecolumns = this.allvisiblecolumns.filter(x => x.isDisplay === true).map(a => a.displayName);
          }


        }
      });

      this.headerColumns = CommonService.getMatColumnAsArray(t.cellDefArray, 'headerName');
      this.columnsDataType = CommonService.getMatColumnAsArray(t.cellDefArray, 'fieldType');
      this.tableProperties = t;
      this.searchFieldDDL = t.cellDefArray.filter((t: cellDef) => {
      
        if (t.fieldType === InputType.Date || t.fieldType === InputType.DateTime) {
      
          this.chipDisplayObj[t.displayName + '_from'] = { headerName: t.headerName + ' From', inputType: t.fieldType };
          this.chipDisplayObj[t.displayName + '_to'] = { headerName: t.headerName + ' To', inputType: t.fieldType };
        } else {
          this.chipDisplayObj[t.displayName] = { headerName: t.headerName, inputType: t.fieldType, format: '' };
        }
        if (t.showInFieldDDl === true) {
          return t;
        }
      });
      if (!isNullOrUndefined(t.deaultFilter)) { this.clsfilter = t.deaultFilter; }
      this.selectedOn = (this.clsfilter.searchField) ? this.clsfilter.searchField : (this.displayedColumns.length > 0 && (isNullOrUndefined(t.defaultSearchField) || t.defaultSearchField === '')) ? this.displayedColumns[0] : t.defaultSearchField;
      this.clsfilter.searchField = this.selectedOn;
      this.advanceSearchObject = { searchCell: this.searchFieldDDL, pageEnum: t.pageEnum, prevSearch: t.deaultFilter.filterArray, focusControl: this.clsfilter.searchField } as IFilterGridInput;
      this.clearFilter();


      if ((this.authService.pageAccessLevel & this.tableProperties.actionButton) > 0) {
        this.displayedColumns.push('action');
        this.headerColumns.push('Action');
      }
      if (!this.tableProperties.isServerSidePaging) {
        this.tableSource.paginator = this.paginator;
        this.tableSource.sort = this.sort;
        this.tableSource.filterPredicate = this.createFilter;
        if (!isNullOrUndefined(this.clsfilter.searchField) && this.clsfilter.searchField.trim() !== '' && !isNullOrUndefined(this.clsfilter.searchValue) && this.clsfilter.searchValue !== '') {
          this.filterObject[this.clsfilter.searchField] = this.clsfilter.searchValue.trim().toLowerCase();
          this.filterLocalEvent();
        }
      }
      this.setFilterObject();
      this.onFilterChangeEvent(this.selectedOn);
      if ((isNullOrUndefined(this.footerDatasource) || Object.keys(this.footerDatasource).length == 0))
        this.setDefaultFooter(t.cellDefArray, this.getCurrentPageData);

      if ((isNullOrUndefined(this.tooltipFields) || this.tooltipFields.size == 0))
        this.setTooltipField(t.cellDefArray);

    }
  }

  constructor(private authService: AuthService, 
              private sharedService: SharedService, 
              private snackbar: SnackBarService, 
              public dialog: MatDialog, 
              private spinner: NgxSpinnerService) {
    this.clsfilter = new CommonFilter();
    this.clsShowfilter = new CommonFilter();
    this.watcher = this.sharedService.shortCutKeyListner.subscribe((t: IShortCutKeys) => {
      this.shortcutKeyEvent(t);
    });

    this.filterObj.value.valueChanges
      .pipe(debounceTime(150))
      .distinctUntilChanged()
      .switchMap(val => this.sharedService.autoCompleteFilter(val, this.filterObj.autoSearchKey.value))
      .subscribe((data: any) => {
        this.autoList = [];
        if (data) {
          this.autoList = data;
        }
      });
  }

  shortcutKeyEvent(t: IShortCutKeys) {
    switch (t.key) {
      case ShortCutKeys.PageDown:
        if (this.paginator.hasPreviousPage()) {
          this.paginator.previousPage();
        }
        break;
      case ShortCutKeys.PageUp:
        if (this.paginator.hasNextPage()) {
          this.paginator.nextPage();
        }
        break;
      case ShortCutKeys.Home:
        if (this.paginator.hasPreviousPage()) {
          this.paginator.firstPage();
        }
        break;
      case ShortCutKeys.End:
        if (this.paginator.hasNextPage()) {
          this.paginator.lastPage();
        }
        break;
      case ShortCutKeys.AltA:
        this.openDialog();
        break;
      case ShortCutKeys.Alt3:
        this.exportReport();
        break;
      case ShortCutKeys.AltC:
        this.action.emit({ actionButon: AccessLevel.Discard, data: null });
        break;
      default:
        break;
    }
  }

  compState = (val1: string, val2: string) => val1 == val2;
  ngOnInit() {
    this.subscription = this.filterObj.value.valueChanges
      .debounceTime(800)
      .distinctUntilChanged()
      .subscribe(newValue => {
        if (this.filterObj.value.valid && this.filterObj.value.value != null) {
          this.clsfilter.searchValue = !(newValue) ? '' : newValue.trim().toLowerCase();
          this.clsfilter.searchField = this.filterObj.field.value;
          if (!this.tableProperties.isServerSidePaging) {
            this.filterObject[this.filterObj.field.value] = this.clsfilter.searchValue;
            this.filterLocalEvent();
            setTimeout(() => {
              this.setDefaultFooter(this.tableProperties.cellDefArray, this.getCurrentPageData);
            }, 100);
          }

          if (this.clsfilter.searchValue === '' && !isNullOrUndefined(this.filterObj.field.value)) {
            this.removeFilterObject({ key: this.filterObj.field.value, value: '' });
          } else {
            this.updateObject({ key: this.filterObj.field.value, value: this.clsfilter.searchValue });
            this.filter.emit({ filter: this.clsfilter });
          }

          this.clsfilter.pageNumber = 1;
          if (this.paginator.hasPreviousPage()) {
            this.paginator.firstPage();
          } else {
           // this.filter.emit({ filter: this.clsfilter });
          }
        }
      });
  }

  setFilterObject() {
    this.filterObj.field.patchValue((isUndefined(this.clsfilter.searchField) ? null : this.clsfilter.searchField));
    this.filterObj.value.patchValue((this.clsfilter.searchValue) ? this.clsfilter.searchValue : null);
  }

  blankSearch() {
    this.filterObj.value.patchValue('');
  }

  setTooltipField(cellDefArray: cellDef[]) {
    cellDefArray.forEach((cell: cellDef) => {
      if (cell.isDisplay) {
        if (cell.tooltipField) {
          this.tooltipFields.set(cell.displayName, cell.tooltipField);
        }
        if (cell.cellCSS) {
          this.cssFields.set(cell.displayName, cell.cellCSS);
        }
      }
    });
  }

  public isVisible(accessNo: number): boolean {
    //  console.log('pageAccessLevel');
    //  console.log(this.authService.pageAccessLevel);
    // console.log('actionButton');
    //  console.log(this.tableProperties.actionButton);
    if ((this.authService.pageAccessLevel & this.tableProperties.actionButton) > 0) {
      return (this.tableProperties.actionButton & accessNo) > 0;
    } else {
      return false;
    }
  }

  onActionButtonClick(item: any, _actionButon: AccessLevel) {
    this.selectedRow = item;
    this.action.emit({ actionButon: _actionButon, data: item });
    // const Currendate = new Date();
    // let Validuptodate = new Date(this.selectedRow.validUptoDate);
    // if (this.pageProperties.currentpage === 130 && _actionButon == 32 && Validuptodate > Currendate){
    //   if (item.status == "Done"){
    //     this.snackbar.success('Please wait, File is downloading!');
    //   }
    //   else if (item.status == "In Process"){
    //     this.snackbar.success('Please wait, File is in Process!');
    //   }
    //   else if (item.status == "Failed"){
    //     this.snackbar.warning('Sorry, File Failed To Generate!');
    //   }
    // }
  }

  exportReport() {
    
    let exPortSource = null;
    if (this.sharedService.tableConfiguration[this.tableProperties.pageEnum]) {
      exPortSource = this.sharedService.tableConfiguration[this.tableProperties.pageEnum];
    } else {
      exPortSource = this.tableProperties.cellDefArray;
    }
    if (exPortSource) {
      this.dialogExportRef = this.dialog.open(ExportSelectionComponent, {
        data: { source: exPortSource, height: CommonService.mainSectionLW.height, filterrequestoption : this.tableProperties.deaultFilter.filterArray
        },
        maxHeight: (CommonService.mainSectionLW.height - 200).toString() + 'px',
        width: '800px',
      });
      this.dialogExportRef.afterClosed().subscribe((t) => {
        if (t) {
          this.spinner.show();
          this.action.emit({ actionButon: AccessLevel.Export, data: t });  
        }
      })
    }
   
  }

  onPageChange(event) {
    this.clsfilter.currentPage = this.tableProperties.pageEnum;
    this.clsfilter.pageSize = event.pageSize;
    this.clsfilter.pageNumber = event.pageIndex + 1;
    this.clsfilter.searchField = this.filterObj.field.value;
    this.clsfilter.searchValue = this.filterObj.value.value;
    this.filter.emit({ filter: this.clsfilter });
    if (!this.tableProperties.isServerSidePaging) {
      this.setDefaultFooter(this.tableProperties.cellDefArray, this.getCurrentPageData);
    }
  }

  public get getCurrentPageData() {
    if (this.tableSource && this.tableSource.filteredData.length > 0 && !this.tableProperties.isServerSidePaging) {
      const startIndex = this.paginator.pageIndex * ((this.paginator.pageSize) ? this.paginator.pageSize : 10);
      const endIndex = startIndex + ((this.paginator.pageSize) ? this.paginator.pageSize : 10);
      return this.tableSource.filteredData.slice(startIndex, endIndex);
    } else {
      return null;
    }
  }

  onSortData(event) {
    
    this.clsfilter.pageNumber = 1;
    if (this.paginator.hasPreviousPage()) {
      this.paginator.firstPage();
    }
    this.clsfilter.orderBy = `${event.active} ${event.direction}`
    this.filter.emit({ filter: this.clsfilter });
  }

  public get getTotalLength() {
    if (!isNullOrUndefined(this.tableSource) && this.tableSource.data.length > 0) {
      if (!this.tableProperties.isServerSidePaging) {
        return this.tableSource.data.length;
      } else {
        return this.tableSource.data[0].totalRecord;
      }
    }
    return 0;
  }

  onFilterChangeEvent(event, keepOpen = true) {
    if (!isNullOrUndefined(event)) {
      this.filterObj.value.setValidators(null);
      this.filterObj.value.disabled ? this.filterObj.value.enable() : null;
      this.searchFieldDDL.filter((t: cellDef) => {
        if (t.displayName === event) {
          this.filterPlaceHolder = `Filter with ${t.headerName}`;
          
          this.filterObj.autoSearchKey.setValue(t.autoSearchKey);
          switch (t.fieldType) {
            case InputType.Phone:
            case InputType.Mobile:
            case InputType.Number:
            case InputType.Amount:
              this.filterObj.value.setValidators([Validators.pattern(CommonService.regExPatern.number)]);
              this.filterValidationError = 'Invalid input';
              break;
           
            case InputType.Date:
            case InputType.DateTime:
              this.advanceSearchObject.focusControl = this.clsfilter.searchField;
              this.filterObj.value.reset();
              this.filterObj.value.disable();
              break;
            case InputType.MultiSelect:  
              this.disabledflg = false; //~~~~~~~~~~~~~~ Tuhsar 04-01-2023 Gid.: 371, 601
              break;
            case InputType.Dropdown:
              if (keepOpen) {
                this.advanceSearchObject.focusControl = this.clsfilter.searchField;
                // this.openDialog();
              }
              break;
            
            default:
              break;
          }
        }
      });
    }
  }

  setDefaultFooter(cellDefArr: Array<cellDef>, currentPageItems = null) {
    if (this.tableFirstRow && this.tableSource && this.tableSource.data) {
      const concatStr = cellDefArr.length > 7 ? " </u> <br />" : "/";
      const underline = cellDefArr.length > 7 ? " <u> " : "";

      cellDefArr.forEach((cell: cellDef) => {
        if (cell.isDisplay) {
          if (cell.footerField && cell.footerField != '') {
            this.footerDisplay = true;

            const val = this.tableSource.data.reduce((sum, j) => sum + CommonService.validateNumber(j[cell.displayName]), 0);
            if (!isNullOrUndefined(this.tableProperties) && !this.tableProperties.isServerSidePaging) {
              if (currentPageItems) {
                const filterTotal = currentPageItems.reduce((sum, j) => sum + CommonService.validateNumber(j[cell.displayName]), 0);
                if (filterTotal != null && val != null && this.footerDatasource && this.footerDatasource[cell.displayName] != null) {
                  if (cell.fieldType == InputType.Amount) {
                    this.footerDatasource[cell.displayName] = `${underline} ${filterTotal.toFixed(2)} ${concatStr} ${val.toFixed(2)} <i class="fa fa-inr color-primary" aria-hidden="true"></i>`;
                  } else {
                    this.footerDatasource[cell.displayName] = `${underline} ${filterTotal.toFixed(2)} ${concatStr} ${val.toFixed(2)}`;
                  }
                }
              } else {
                this.footerDatasource[cell.displayName] = '';
              }
            } else {
              if (val != null && this.tableFirstRow && this.tableFirstRow[cell.footerField] != null) {
                const grandTotal = this.tableFirstRow[cell.footerField].toFixed(2) == 0 ? 0 : this.tableFirstRow[cell.footerField].toFixed(2);
                if (cell.fieldType == InputType.Amount) {
                  this.footerDatasource[cell.displayName] = `${underline} ${val.toFixed(2)} ${concatStr} ${grandTotal} <i class="fa fa-inr color-primary" aria-hidden="true"></i>`;
                } else {
                  this.footerDatasource[cell.displayName] = `${underline} ${val.toFixed(2)} ${concatStr} ${grandTotal}`;
                }
              }
            }
          } else {
            this.footerDatasource[cell.displayName] = '';
          }
        }
      });
      if (this.footerDisplay === true)
        this.footerDatasource[cellDefArr[0].displayName] = (concatStr == "/" ? 'Total:' : "<u>Page Total:</u> <br /> Grand Total: ");
    }
  }

  openDialog() {
    this.advanceSearchObject.prevSearch = this.clsfilter.filterArray;
    this.dialogRef = this.dialog.open(FilterGridComponent, {
      data: this.advanceSearchObject,
      maxHeight: '400px',
      width: '600px',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      this.applyAdvanceFilter(result);
    });
  }

  private applyAdvanceFilter(result: any) {
   
    if (!isNullOrUndefined(result)) {
      const tempObj = CommonService.copyObject(this.sharedService.sharedMaster[this.tableProperties.pageEnum].searchFilter);
      if (!this.tableProperties.isServerSidePaging) {
        this.clearFilter();
        result.forEach((t: IHashTable) => {
          if (!isNullOrUndefined(t.key)) {
            this.filterObject[t.key] = t.value;
          }
        });
      }
      this.clsfilter.filterArray = result;
      if (tempObj != this.clsfilter) {
        this.sharedService.sharedMaster[this.tableProperties.pageEnum].searchFilter = this.clsfilter;
        if (result.length === 0) {
          this.clsfilter.searchValue = '';
          if (this.filterObj.value.value !== '') {
            this.setFilterObject();
          }
          if (this.tableProperties.isServerSidePaging)
            this.filter.emit({ filter: this.clsfilter });
        } else if (result.length === 1) {
          this.clsfilter.searchField = result[0].key;
          this.clsfilter.searchValue = result[0].value;
          this.onFilterChangeEvent(result[0].key, false);
          this.setFilterObject();
        } else if (this.tableProperties.isServerSidePaging) {
          this.filter.emit({ filter: this.clsfilter });
        } else if (!this.tableProperties.isServerSidePaging) {
          this.filterLocalEvent()
        }
      }
    } else {
      this.clsfilter.searchValue = '';
      this.setFilterObject();
    }
  }

  private clearFilter() {
    this.displayedColumns.forEach(element => { this.filterObject[element] = ''; });
  }

  private filterLocalEvent() {
    this.tableSource.filter = JSON.stringify([(isNullOrUndefined(this.searchFieldDDL) ? [] : this.searchFieldDDL), this.filterObject]);
  }

  get createFilter(): (data: any, filter: string) => boolean {
    return function (data: any, filter: string): boolean {
      let searchObject = JSON.parse(filter); // searchObject[0] searchFieldDDL 
      const searchTerms = searchObject[1];
       let searchValueExists = false;      
      for (const key in searchTerms) {
        if (searchTerms[key]) {
          searchValueExists = true;
          break;
        }
      }
      if (searchValueExists) {
        let tot = Object.values(searchTerms).every(x => (isNullOrUndefined(x) || String(x).trim() === ''));
        let hasArr = [];
        if (tot === false) {
          searchObject[0].forEach(t => {
            switch (t.fieldType) {
              case InputType.Date:
              case InputType.DateTime:
                if (!isNullOrUndefined(data[t.displayName]) && data[t.displayName].toString().trim() !== ''
                  && !isNullOrUndefined(searchTerms[t.displayName]) && isDate(searchTerms[t.displayName + '_from']) && isDate(searchTerms[t.displayName + '_to'])) {
                  hasArr.push((new Date(data[t.displayName]) >= new Date(searchTerms[t.displayName + '_from']) && new Date(data[t.displayName]) <= new Date(searchTerms[t.displayName + '_to'])));
                }
                break;
              default:
                if (data[t.displayName] && data[t.displayName] != null && searchTerms[t.displayName] && searchTerms[t.displayName].toString().trim() !== '') {
                  hasArr.push(((!data[t.displayName]) ? false : (data[t.displayName].toString().trim().toLowerCase().indexOf(searchTerms[t.displayName].trim().toLowerCase()) !== -1)));
                }
                break;
            }
          });
        }
        return hasArr.length > 0 ? Object.values(hasArr).every(x => (x === true)) : false;
      } else {
        return true;
      }
    }
  }

  removeFilterObject(item) {
    if ((this.clsfilter.filterArray) && this.clsfilter.filterArray.length > 0) {
      if (this.chipDisplayObj[item.key].inputType === InputType.Date || this.chipDisplayObj[item.key].inputType === InputType.DateTime) {
        let fromKey = '';
        if (item.key.indexOf('_from') !== -1) {
          fromKey = item.key.replace('_from', '_to');
        } else {
          fromKey = item.key.replace('_to', '_from');
        }
        this.clsfilter.filterArray = this.clsfilter.filterArray.filter((t) => { if (t.key != item.key && t.key != fromKey) { return t; } });
      } else {
        this.clsfilter.filterArray = this.clsfilter.filterArray.filter((t) => { if (t.key != item.key) { return t; } });
      }
      this.applyAdvanceFilter(this.clsfilter.filterArray);
    }
  }

  updateObject(item) {
    if (this.clsfilter.filterArray) {
      if (this.clsfilter.filterArray.find(t => t.key === item.key)) {
        var index = this.clsfilter.filterArray.findIndex(t => t.key === item.key)
        if (index > -1) {
          this.clsfilter.filterArray[index].value = item.value;
        }
        // this.clsfilter.filterArray = this.clsfilter.filterArray.filter((t) => { if (t.key == item.key) { t.value = item.value; return t; } });
      } else {
        this.clsfilter.filterArray.push(item);
      }
    }
  }
  openSubReport(rowItem, cellName) {
    try {
      const cell = this.tableProperties.cellDefArray.find((t => t.displayName == cellName));
      if (cell.subReportId > 0) {
        const req = [];
        req.push(this.sharedService.getTableConfiguration(cell.subReportId));
        req.push(this.sharedService.getReportConfiguration(cell.subReportId));
        forkJoin(req).subscribe((t: any[]) => {
          if (t[0] && t[0].data && t[0].data.length > 0) {
            const _headerData = {};
            this.tableProperties.cellDefArray.forEach(element => {
              if (element.isDisplay) {
                if ((element.fieldType == InputType.Date || element.fieldType == InputType.DateTime) && rowItem[element.displayName]) {
                  _headerData[element.displayName] = { key: element.headerName, value: new Date(rowItem[element.displayName]).toISOString().split('T')[0] };
                }
                else {
                  _headerData[element.displayName] = { key: element.headerName, value: rowItem[element.displayName] };
                }
              }
            });
            this.dialogSubReport = this.dialog.open(SubReportComponent, {
              data:
              {
                tablerConfig: t[0].data,
                reportInfo: t[1].data,
                displayColumns: this.displayedColumns,
                selectedRecord: rowItem,
                headerData: _headerData,
                selectedCell: cell
              },
              width: String(CommonService.mainSectionLW.width) + 'px',
              height: String(CommonService.mainSectionLW.height - 100) + 'px',
            });
          } else {
            this.snackbar.info('Sub Report Configuration not found');
          }
        });
      } else {
        this.snackbar.info('Sub Report Configuration not found');
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.watcher){
      this.watcher.unsubscribe();
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    if (this.dialogSubReport) {
      this.dialogSubReport.close();
    }
    if(this.dialogExportRef){
      this.dialogExportRef.close();
    }
  }

  oncolumnVisibilityChanged(event: any) {
    //To Add Checked item in Array
    this.visiblecolumns.filter((element, index) => {
      let indx = this.displayedColumns.findIndex(x => x === element);
      if (indx === -1) {
        let hdrname = this.allvisiblecolumns.find(x => x.displayName === element);
        this.headerColumns.splice(index, 0, hdrname.headerName);
        this.columnsDataType.splice(index, 0, hdrname.fieldType);
        this.displayedColumns.splice(index, 0, element);
      }
    });

    //To Remove Checkd item From Array
    let needtoremoveindexes = [];
    this.headerColumns = this.headerColumns.filter((element, index) => {
      if (element.toUpperCase() != 'ACTION') {
        let columdata = this.allvisiblecolumns.find(x => x.headerName === element);
        let indx = this.visiblecolumns.findIndex(x => x === columdata.displayName);
        if (indx >= 0) {
          return element;
        }
        else {
          needtoremoveindexes.push(index);

        }
      }
      else {
        return element;
      }

    });

    let setremovedindex = new Set(needtoremoveindexes);
    this.columnsDataType = this.columnsDataType.filter((element, index) => {
      return !setremovedindex.has(index);
    })

    this.displayedColumns = this.displayedColumns.filter((element, index) => {
      if (element.toUpperCase() != 'ACTION') {
        let indx = this.visiblecolumns.findIndex(x => x === element);
        if (indx >= 0) {
          return element;
        }
      } else {
        return element;
      }
    });
  }

  saveuserColumnPrefrence() {
    let postdata = {
      Pageid: this.pageProperties.currentpage,
      VisibleColumnsArray: this.visiblecolumns
    }
    this.sharedService.customPostApi("common/usercolumnprefrence", postdata).subscribe(x => {

    });
  }
  opensendEmail()
  {
    this.clsShowfilter= this.clsfilter;

    this.dialogSendEmail = this.dialog.open(EmailSchedulerComponentComponent, {
      maxHeight: '800px',
      width: '1200px',
      data : {searchdata: this.clsfilter, chipDisplay :this.chipDisplayObj,showDisplay : this.clsShowfilter } 
    });

 
  }

}


// export enum TableButtons {
//   Select = 1,
//   Add = 2,
//   Edit = 4,
//   Delete = 8,
//   Print = 32
// }
// onAction(selectedItem) {
//   this.pageProperties.selectedRow = selectedItem.data;
//   switch (selectedItem.actionButon) {
//     case TableButtons.View:
//       break;
//     case AccessLevel.New:
//       break;
//     case AccessLevel.Delete:
//       break;
//     case AccessLevel.Edit:
//       break;
//     case AccessLevel.Print:
//       break;
//     default:
//       break;
//   }
// }