import { Injectable } from '@angular/core';
import * as Rx from "rxjs/Rx";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private ws;
  socket: any;
  /**
   *
   */
  constructor() { }

  private subject: Rx.Subject<MessageEvent>;

  public connect(url): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log("Successfully connected: " + url);
    }
    return this.subject;
  }

  private create(url): Rx.Subject<MessageEvent> {
    try {
      this.ws = new WebSocket(url);
      let observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
        this.ws.onmessage = obs.next.bind(obs);
        this.ws.onerror = obs.error.bind(obs);
        this.ws.onclose = obs.complete.bind(obs);
        return this.ws.close.bind(this.ws);
      }).share();
      let observer = {
        next: (data: Object) => {
          if (this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(data));
          } else {
          }
        }
      };
      return Rx.Subject.create(observer, observable);
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }
  
  public close() {
    
    console.log('on closing WS');
    if (this.ws) {
      this.ws.close();
    }
    if (this.subject) {
      this.subject.unsubscribe();
      this.subject = null
    }
  }

}
