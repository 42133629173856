import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-multiple-validation-message',
  templateUrl: './multiple-validation-message.component.html',
  styleUrls: ['./multiple-validation-message.component.css']
})
export class MultipleValidationMessageComponent implements OnInit {
  
  messageList: any;
  constructor(public dialogRef: MatDialogRef<MultipleValidationMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public confModel: any) { 
      
      this.messageList=confModel;
    }

  ngOnInit() {
  }

}
