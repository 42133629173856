import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FilterObject, SharedService } from '../../../shared/shared.service';
import { CommonService } from '../../../common-services/common-functions.service';
import { CPOSEnum, InputType } from '../../../common-services/enum.service';
import { cellDef, CommonFilter, TableObject } from '../../../common-services/models.service';
import { SnackBarService } from '../../../common-services/snack-bar.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-update-inactivestore',
  templateUrl: './update-inactivestore.component.html',
  styleUrls: ['./update-inactivestore.component.css']
})
export class UpdateInactivestoreComponent implements OnInit {

  updateinactiveform: FormGroup;
  dataSource = new MatTableDataSource<any>([]);
  tableObject: TableObject;
  tableCellArray: any;
  pageProperties = {
    sectionHeight: 0,
    selectedRow: null,
    filter: null
  };
  curPageNo: number = 1;
  curPageSize: number = 10;
  curOrderBy: string = '';
  storeData: any;
  IsLoaderShow: boolean = false;
  dispTitle: string = '';
  constructor(public dialogRef: MatDialogRef<UpdateInactivestoreComponent>, @Inject(MAT_DIALOG_DATA)
  public data: any,private fg: FormBuilder, private sharedService: SharedService, private notification: SnackBarService,) { }

  ngOnInit() {
    this.storeData =  this.data.data;
    if (this.storeData.storeNO != null && this.storeData.storeNO != null)
    {
      this.dispTitle = " - " + this.storeData.storeNO ;
    }
    else
    {
      this.dispTitle = " - " + this.storeData.secn_MobineNo;
    }
    this.BindForm();
    this.fillTable();
    this.ResizeEvent();
  }

  BindForm() {
    this.updateinactiveform = this.fg.group({
      'remarks': [(null), [Validators.required]]
    });
  }

  fillTable() {
    this.tableCellArray = this.getTableCellArray();
    this.tableObject = new TableObject(this.tableCellArray.tableCellArray, 0, true, this.tableCellArray.defaultSearch, 
      this.getFilter, CPOSEnum.updateinactivestore, false);
    this.filterTable();

  }

  getTableCellArray(): any {
    const _tableCellArray = [];
    let _defaultSearch = '';
    _tableCellArray.push(new cellDef('userName', 'User Name', false, InputType.Text, null, null, null, 'ST', null, true, null, null));
    _tableCellArray.push(new cellDef('remarks', 'Remarks', false, InputType.Text, null, null, null, 'ST', null, true, null, null));
    _tableCellArray.push(new cellDef('remarkDate', 'Date', false, InputType.Date, null, null, null, 'ST', null, true, null, null));    
    return { tableCellArray: _tableCellArray, defaultSearch: _defaultSearch };
  }

  public get getFilter(): CommonFilter {
    if (isNullOrUndefined(this.sharedService.sharedMaster[CPOSEnum.updateinactivestore])) {
      this.sharedService.sharedMaster[CPOSEnum.updateinactivestore] = new FilterObject(new CommonFilter(CPOSEnum.updateinactivestore));
    }
    return this.sharedService.sharedMaster[CPOSEnum.updateinactivestore].searchFilter;
  }

  private ResizeEvent() {
    setTimeout(() => {
      this.pageProperties.sectionHeight = CommonService.mainSectionLW.height - 200;
    }, 100);
  }

  onFilterEvent(event) {
    this.curPageNo = event.filter.pageNumber;
    this.curPageSize = event.filter.pageSize;
    this.curOrderBy = event.filter.orderBy;
    this.filterTable();
  }

  filterTable() {
    const filterdata = {
      PageSize: this.getFilter.pageSize,
      PageNumber: this.curPageNo,
      OrderBy: this.curOrderBy,
      StoreId: this.storeData.storeId
    }
    this.sharedService.customPostApi('storenotification/storeremarkslist', filterdata).subscribe((t) => {
      if (t && t.status === true) {
        this.dataSource = new MatTableDataSource<any>(t.data);
      } else {

      }

    });
  }

  onSave() {
    
    if (this.updateinactiveform.valid) {
      this.sharedService.customPostApi('storenotification/addstoreremark', {
        StoreId: this.storeData.storeId,
        StoreNo: this.storeData.storeNO,        
        Remarks:this.updateinactiveform.get('remarks').value
      }).subscribe((t) => {
        this.IsLoaderShow = false;
        if (t.status == true) {
          this.notification.success(t.message);
          this.dialogRef.close(true);
          //this.BindForm();
          //this.filterTable();          
        }
        else {
          this.notification.error(t.data);
        }
      }, (error) => {
        this.IsLoaderShow = false;
      });
    }
    else{
      Object.keys(this.updateinactiveform.controls).forEach(field => {
        const control = this.updateinactiveform.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}
