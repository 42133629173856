import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Logger } from './common-services/common-functions.service';
import { CommunicationService } from './common-services/communication.service';
import { KEY_CODE, ShortCutKeys } from './common-services/enum.service';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(private authService: AuthService, private sharedService: SharedService, private webSocket: CommunicationService) { }
  showLoader: boolean = false;
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEY_CODE.PAGE_UP:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.PageUp, description: '' });
        break;
      case KEY_CODE.PAGE_DOWN:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.PageDown, description: '' });
        break;
      case KEY_CODE.HOME:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Home, description: '' });
        break;
      case KEY_CODE.END:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.End, description: '' });
        break;
      case KEY_CODE.DELETE:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Delete, description: '' });
        break;
      case KEY_CODE.ENTER:
        this.sharedService.shortCutKeyListner.next({ key: ShortCutKeys.Enter, description: '' });
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    
    this.sharedService.showLoader.subscribe((t: boolean) => {
      this.showLoader = t;
    });
    if (this.authService.isAuthenticated) {
      Logger.Normal('Start From App Module');
      this.authService.initIdleService();
      // this.webSocket.connectWebSocket(this.authService.getUserName);
    }

    // this.sharedService.customGetApi('common/test').subscribe((t: any) => {
    //   if (t === 'ok') {
    //     if (this.authService.isAuthenticated) {
    //       Logger.Normal('Start From App Module');
    //       this.authService.initIdleService();
    //       this.webSocket.connectWebSocket(this.authService.getUserName);
    //     }
    //   } else {
    //     this.authService.logOut();
    //   }
    // }, (error) => {
    //   console.log(error);
    //   this.authService.logOut();
    // });

  }

  ngAfterViewInit() { }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.authService.disconnectWebSocket();
  }

}
