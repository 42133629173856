import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SnackBarService } from '../../common-services/snack-bar.service';

@Component({
  selector: 'app-snackbar-component',
  templateUrl: './snackbar-component.component.html',
  styleUrls: ['./snackbar-component.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponentComponent implements OnInit {

  messageType = '';
  icon = '';
  title = '';
  message = '';
  constructor() { }

  ngOnInit() {

  }

}
